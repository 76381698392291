/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ContactUsInput,
  HomeState,
  PoolModel,
  SportTypeModel,
} from '../../common/types';

const initialState: HomeState = {
  loading: false,
  success: null,
  errors: [],
  pools: null,
  sportTypes: null,
  poolState: {
    loading: false,
    success: null,
    errors: [],
  },
  sportTypeState: {
    loading: false,
    success: null,
    errors: [],
  },
  addContactUs: {
    loading: false,
    success: null,
    errors: [],
  },
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPools(state) {
      state.poolState.loading = true;
      state.poolState.success = null;
      state.poolState.errors = [];
    },
    loadPoolsSuccess(state, action: PayloadAction<PoolModel[]>) {
      state.poolState.loading = false;
      state.pools = action.payload;
      state.poolState.success = !!action.payload;
    },
    loadPoolsError(state, action: PayloadAction<string[]>) {
      state.poolState.loading = false;
      state.poolState.errors = action.payload;
    },
    loadSportTypes(state) {
      state.sportTypeState.loading = true;
      state.sportTypeState.success = null;
      state.sportTypeState.errors = [];
    },
    loadSportTypesSuccess(state, action: PayloadAction<SportTypeModel[]>) {
      state.sportTypeState.loading = false;
      state.sportTypes = action.payload;
      state.sportTypeState.success = !!action.payload;
    },
    loadSportTypesError(state, action: PayloadAction<string[]>) {
      state.sportTypeState.loading = false;
      state.sportTypeState.errors = action.payload;
    },
    resetLoadSportTypesSuccess(state) {
      state.sportTypeState.success = null;
    },
    addContactUs(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ContactUsInput>,
    ) {
      state.addContactUs.loading = true;
      state.addContactUs.errors = [];
    },
    addContactUsSuccess(state, action: PayloadAction<boolean>) {
      state.addContactUs.loading = false;
      state.addContactUs.success = action.payload;
    },
    addContactUsError(state, action: PayloadAction<string[]>) {
      state.addContactUs.loading = false;
      state.addContactUs.errors = action.payload;
    },
    resetAddContactUsSuccess(state) {
      state.addContactUs.success = null;
    },
  },
});

export const {
  loadPools,
  loadPoolsSuccess,
  loadPoolsError,
  loadSportTypes,
  loadSportTypesSuccess,
  loadSportTypesError,
  resetLoadSportTypesSuccess,
  addContactUs,
  addContactUsSuccess,
  addContactUsError,
  resetAddContactUsSuccess,
} = homeSlice.actions;

export const { name: homeSliceName, reducer: homeSliceReducer } = homeSlice;
