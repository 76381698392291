/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CheckLoginOTPArgs,
  LoginInput,
  LoginState,
  SendLoginOTPArgs,
  SocialLoginInput,
  UserModel,
} from '../../common/types';

const initialState: LoginState = {
  loading: false,
  success: null,
  errors: [],
  whoamiState: {
    loading: false,
    success: null,
    errors: [],
  },
  socialLoginState: {
    loading: false,
    success: null,
    errors: [],
  },
  sendLoginOTP: {
    loading: false,
    success: null,
    errors: [],
  },
  checkLoginOTP: {
    loading: false,
    success: null,
    errors: [],
  },
  accessToken: null,
  user: null,
  userCheckDone: false,
  socialLoginCheck: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login(state, action: PayloadAction<LoginInput>) {
      state.loading = true;
      state.success = null;
      state.errors = [];

      state.accessToken = null;
      state.user = null;
    },
    loginSuccess(state, action: PayloadAction<UserModel>) {
      state.loading = false;
      state.success = true;

      state.accessToken = action.payload.accessToken || null;
    },
    loginError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    socialLogin(state, action: PayloadAction<SocialLoginInput>) {
      state.socialLoginState.loading = true;
      state.socialLoginState.success = null;
      state.socialLoginState.errors = [];

      state.socialLoginCheck = null;
    },
    socialLoginSuccess(state, action: PayloadAction<UserModel | null>) {
      state.socialLoginState.loading = false;
      state.socialLoginState.success = true;

      state.socialLoginCheck = action.payload;
    },
    socialLoginError(state, action: PayloadAction<string[]>) {
      state.socialLoginState.loading = false;
      state.socialLoginState.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    whoami(state, action: PayloadAction) {
      state.whoamiState.loading = true;
      state.whoamiState.success = false;
      state.whoamiState.errors = [];

      state.userCheckDone = false;
    },
    whoamiSuccess(state, action: PayloadAction<UserModel | null>) {
      state.whoamiState.loading = false;
      state.whoamiState.success = true;

      state.user = action.payload;
      state.userCheckDone = true;
    },
    whoamiError(state, action: PayloadAction<string[]>) {
      state.whoamiState.loading = false;
      state.whoamiState.errors = action.payload;

      state.userCheckDone = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout(state, action: PayloadAction) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutSuccess(state, action: PayloadAction) {
      state.loading = false;
      state.success = null;
      state.errors = [];

      state.whoamiState.loading = false;
      state.whoamiState.success = null;
      state.whoamiState.errors = [];

      state.accessToken = null;
      state.user = null;
      state.userCheckDone = false;
    },
    sendLoginOTP(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<SendLoginOTPArgs>,
    ) {
      state.sendLoginOTP.loading = true;
      state.sendLoginOTP.errors = [];
    },
    sendLoginOTPSuccess(state, action: PayloadAction<boolean>) {
      state.sendLoginOTP.loading = false;
      state.sendLoginOTP.success = !!action.payload;
    },
    sendLoginOTPError(state, action: PayloadAction<string[]>) {
      state.sendLoginOTP.loading = false;
      state.sendLoginOTP.errors = action.payload;
    },
    resetSendLoginOTPSuccess(state) {
      state.sendLoginOTP.success = null;
      state.sendLoginOTP.errors = [];
    },
    checkLoginOTP(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<CheckLoginOTPArgs>,
    ) {
      state.checkLoginOTP.loading = true;
      state.checkLoginOTP.errors = [];
    },
    checkLoginOTPSuccess(state, action: PayloadAction<boolean>) {
      state.checkLoginOTP.loading = false;
      state.checkLoginOTP.success = !!action.payload;
    },
    checkLoginOTPError(state, action: PayloadAction<string[]>) {
      state.checkLoginOTP.loading = false;
      state.checkLoginOTP.errors = action.payload;
    },
    resetCheckLoginOTPSuccess(state) {
      state.checkLoginOTP.success = null;
      state.checkLoginOTP.errors = [];
    },
  },
});

export const {
  login,
  loginSuccess,
  loginError,
  socialLogin,
  socialLoginSuccess,
  socialLoginError,
  whoami,
  whoamiSuccess,
  whoamiError,
  logout,
  logoutSuccess,
  sendLoginOTP,
  sendLoginOTPSuccess,
  sendLoginOTPError,
  resetSendLoginOTPSuccess,
  checkLoginOTP,
  checkLoginOTPSuccess,
  checkLoginOTPError,
  resetCheckLoginOTPSuccess,
} = loginSlice.actions;

export const { name: loginSliceName, reducer: loginSliceReducer } = loginSlice;
