/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  AppRoutePath,
  PublicRoutePath,
  TOKEN_KEY,
} from '../../common/constants';
import {
  CheckLoginOTPArgs,
  LoginInput,
  SendLoginOTPArgs,
  SocialLoginInput,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  checkLoginOTP,
  checkLoginOTPError,
  checkLoginOTPSuccess,
  login,
  loginError,
  loginSuccess,
  logout,
  logoutSuccess,
  sendLoginOTP,
  sendLoginOTPError,
  sendLoginOTPSuccess,
  socialLogin,
  socialLoginError,
  socialLoginSuccess,
  whoami,
  whoamiError,
  whoamiSuccess,
} from './login.slice';

export function* loginAsync(action: PayloadAction<LoginInput>) {
  const { page } = action.payload;
  const { data, errors } = yield call(ApiService.login, action.payload);

  if (errors) {
    yield put(loginError(errors));
  } else if (data.twoFA && data.twoFA.isEnabled) {
    yield put(push(AppRoutePath.LoginVerify, data));
  } else {
    yield call(
      [localStorage, localStorage.setItem],
      TOKEN_KEY,
      data.access_token,
    );
    yield put(loginSuccess(data));
    yield put(whoami());
    if (page === 'login') {
      yield put(push(AppRoutePath.Home));
    }
  }
}

export function* socialLoginAsync(action: PayloadAction<SocialLoginInput>) {
  const { page } = action.payload;
  const { data, errors } = yield call(ApiService.socialLogin, action.payload);

  if (errors) {
    yield put(socialLoginError(errors));
  } else if (data._id) {
    yield call(
      [localStorage, localStorage.setItem],
      TOKEN_KEY,
      data.access_token,
    );
    yield put(socialLoginSuccess(data));
    yield put(whoami());
    if (page === 'login') {
      yield put(push(AppRoutePath.Home));
    }
  } else {
    yield put(push(PublicRoutePath.Register, action.payload));
  }
}

export function* whoamiAsync() {
  const { data, errors } = yield call(ApiService.whoami);

  if (errors) {
    yield put(whoamiError(errors));
  } else {
    yield put(whoamiSuccess(data));
  }
}

export function* logoutAsync() {
  yield call([localStorage, localStorage.removeItem], TOKEN_KEY);
  yield put(logoutSuccess());
  window.location.replace('/');
}

export function* sendLoginOTPAsync(action: PayloadAction<SendLoginOTPArgs>) {
  const { data, errors } = yield call(ApiService.sendLoginOTP, action.payload);

  if (errors) {
    yield put(sendLoginOTPError(errors));
  } else {
    yield put(sendLoginOTPSuccess(data));
  }
}

export function* checkLoginOTPAsync(action: PayloadAction<CheckLoginOTPArgs>) {
  const { data, errors } = yield call(ApiService.checkLoginOTP, action.payload);

  if (errors) {
    yield put(checkLoginOTPError(errors));
  } else {
    yield call(
      [localStorage, localStorage.setItem],
      TOKEN_KEY,
      data.access_token,
    );
    yield put(loginSuccess(data));
    yield put(whoami());

    yield put(push(AppRoutePath.Home));

    yield put(checkLoginOTPSuccess(data));
  }
}

export function* watchLoginAsync() {
  yield takeLatest(login.toString(), loginAsync);
  yield takeLatest(whoami.toString(), whoamiAsync);
  yield takeLatest(logout.toString(), logoutAsync);
  yield takeLatest(socialLogin.toString(), socialLoginAsync);
  yield takeLatest(sendLoginOTP.toString(), sendLoginOTPAsync);
  yield takeLatest(checkLoginOTP.toString(), checkLoginOTPAsync);
}
