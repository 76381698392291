import { Field, Form, Formik } from 'formik';
import React from 'react';

import LanguageTexts from '../../common/language';
import { ContactUsInput } from '../../common/types';

type ContactUsFormProps = {
  onSubmit: (data: ContactUsInput) => void;
  errors: string[];
  loading: boolean;
  initialValues: ContactUsInput;
};

const ContactUsForm: React.FC<ContactUsFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
}: ContactUsFormProps): JSX.Element => {
  const { app: appTxt, contactUs: contactUsTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.name) {
      errors.name = contactUsTxt.nameErrorText;
    } else if (!/^[a-zA-Z ]*$/.test(values.name)) {
      errors.name = contactUsTxt.nameInvalidErrorText;
    }

    if (!values.email) {
      errors.email = contactUsTxt.emailErrorText;
    } else if (!validateEmail(values.email)) {
      errors.email = contactUsTxt.emailInvalidErrorText;
    }

    if (!values.message) {
      errors.message = contactUsTxt.messageErrorText;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initVals}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="php-email-form home-page-contact-form">
          <div className="form-group">
            <Field
              name="name"
              type="text"
              className="form-control border-bottom border-primary home-page-contact-form"
              id="name"
              placeholder={contactUsTxt.name}
            />
            {errors.name && touched.name && (
              <div className="invalid-feedback-msg">{errors.name}</div>
            )}
          </div>
          <div className="form-group">
            <Field
              name="email"
              type="text"
              className="form-control border-bottom border-primary home-page-contact-form"
              id="email"
              placeholder={contactUsTxt.email}
            />
            {errors.email && touched.email && (
              <div className="invalid-feedback-msg">{errors.email}</div>
            )}
          </div>
          <div className="form-group">
            <Field
              name="phone"
              type="text"
              className="form-control border-bottom border-primary home-page-contact-form"
              id="phone"
              placeholder={contactUsTxt.phone}
            />
          </div>
          <div className="form-group">
            <Field
              name="message"
              as="textarea"
              className="form-control border-bottom border-primary home-page-contact-form"
              id="message"
              placeholder={contactUsTxt.message}
            />
            {errors.message && touched.message && (
              <div className="invalid-feedback-msg">{errors.message}</div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary text-uppercase"
            disabled={loading}
          >
            {loading ? appTxt.sending : contactUsTxt.sendMessageBtnText}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactUsForm;
