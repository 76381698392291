export enum PublicRoutePath {
  Login = '/login',
  Register = '/register',
  RegisterSuccess = '/success',
  ForgotPass = '/forgot-password',
  EmailVerifySuccess = '/email-verify-success',
}

export enum AppRoutePath {
  Home = '/',
  Game = '/games/:pool',
  LoginVerify = '/login-verify',
  LoginVerifyOtp = '/login-verify-otp',
  Profile = '/profile',
  Account = '/account',
  Deposit = '/deposit',
  Withdraw = '/withdraw',
  AddCards = '/add-cards',
}

export enum BetTypes {
  PS = 'Point Spread',
  ML = 'Money Line',
  TP = 'Total Points',
  PARLAY = 'Parlay',
}

export enum TeamTypes {
  HOME = 'home',
  AWAY = 'away',
}

export const TOKEN_KEY = 'accessToken';

export enum TransactionType {
  POOL_JOIN = 'POOL_JOIN',
  ADD_MONEY_TO_WALLET = 'ADD_MONEY_TO_WALLET',
  ADD_WIN_AMOUNT_TO_WALLET = 'ADD_WIN_AMOUNT_TO_WALLET',
  DEDUCT_POOL_AMOUNT_FROM_WALLET = 'DEDUCT_POOL_AMOUNT_FROM_WALLET',
}
