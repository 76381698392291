/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';
import { loadMyPoolBets } from '../profile.slice';
import SidePanelPage from '../side-panel/SidePanelPage';
import PoolUserBets from './PoolUserBets';

const AccountDetailsPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { app: appTxt, account: accountTxt } = LanguageTexts;
  const [tab, setTab] = useState('Current');
  const { myPoolBets } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    dispatch(loadMyPoolBets(tab));
  }, [tab, dispatch]);

  const changeTab = (val: string) => {
    setTab(val);
  };

  const windowScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div id="main-wrapper" className="bg-primary gradient-color userpage">
        <div id="content" className="py-4 main-user-section">
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
            alt=""
            className="shape-1"
          />
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
            alt=""
            className="shape-2"
          />
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <SidePanelPage />
              </aside>
              <div className="col-lg-9">
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <h3 className="text-8 font-weight-400 mb-4">
                    {accountTxt.accountHeadingText}
                  </h3>
                  <div className="row profile-completeness">
                    <div className="col-6 col-md-3 mb-4">
                      <div className="border rounded p-3 text-center">
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-user" />
                        </span>
                        <p className="mb-0">
                          <Link
                            className="btn-link stretched-link"
                            to="/profile"
                            onClick={windowScrollTop}
                          >
                            {accountTxt.viewProfileText}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-4">
                      <div className="border rounded p-3 text-center">
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-dollar-sign" />
                        </span>
                        <p className="mb-0">
                          <Link
                            className="btn-link stretched-link"
                            to="/deposit"
                            onClick={windowScrollTop}
                          >
                            {accountTxt.addMoneyText}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-4">
                      <div className="border rounded p-3 text-center">
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="far fa-credit-card" />
                        </span>
                        <p className="mb-0">
                          <Link
                            className="btn-link stretched-link"
                            to="/add-cards"
                            onClick={windowScrollTop}
                          >
                            {accountTxt.addCardsText}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 mb-4">
                      <div className="border rounded p-3 text-center">
                        <span className="d-block text-10 text-light mt-2 mb-3">
                          <i className="fas fa-hand-holding-usd" />
                        </span>
                        <p className="mb-0">
                          <Link
                            className="btn-link stretched-link"
                            to="/withdraw"
                            onClick={windowScrollTop}
                          >
                            {accountTxt.withdrawText}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <Link
                      className="btn-link back-to-gamepage"
                      to="/games/daily-pool"
                    >
                      {accountTxt.backToGamePage}
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 list-tab">
                    <div
                      className="list-group flex-direction-row bet-tab-list"
                      id="list-tab"
                      role="tablist"
                    >
                      <a
                        className="list-group-item list-group-item-action active w-auto"
                        id="list-current-games"
                        data-toggle="list"
                        href="javascript;"
                        data-target="#current-games"
                        onClick={() => changeTab('Current')}
                        role="tab"
                        aria-controls="current-games"
                      >
                        {accountTxt.activePoolsTab}
                      </a>
                      <a
                        className="list-group-item list-group-item-action w-auto"
                        id="list-previous-games"
                        data-toggle="list"
                        href="javascript;"
                        data-target="#previous-games"
                        onClick={() => changeTab('Previous')}
                        role="tab"
                        aria-controls="previous-games"
                      >
                        {accountTxt.previousPoolsTab}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active pool-game-bet-list"
                        id="current-games"
                        role="tabpanel"
                        aria-labelledby="list-current-games"
                      >
                        {myPoolBets && myPoolBets.length > 0 ? (
                          <PoolUserBets
                            records={myPoolBets || []}
                            title="Active Pools"
                          />
                        ) : myPoolBets !== null ? (
                          <div className="d-flex align-items-center py-4 pl-2">
                            <h6 className="text-white text-6">
                              {appTxt.noRecordsFound}
                            </h6>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center py-4 pl-2">
                            <h6 className="text-6">{appTxt.loading}</h6>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade pool-game-bet-list"
                        id="previous-games"
                        role="tabpanel"
                        aria-labelledby="list-previous-games"
                      >
                        {myPoolBets && myPoolBets.length > 0 ? (
                          <PoolUserBets
                            records={myPoolBets || []}
                            title="Previous Pools"
                          />
                        ) : myPoolBets !== null ? (
                          <div className="d-flex align-items-center py-4 pl-2">
                            <h6 className="text-white text-6">
                              {appTxt.noRecordsFound}
                            </h6>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center py-4 pl-2">
                            <h6 className="text-6">{appTxt.loading}</h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDetailsPage;
