import React, { FunctionComponent } from 'react';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';

interface GoogleSignInComponentProps {
  loginSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => void;
}

export const GoogleSignIn: FunctionComponent<GoogleSignInComponentProps> = ({
  loginSuccess,
}) => {
  return (
    <div className="text-center mb-4">
      <GoogleLogin
        clientId="922645319414-v5up477j9b4ag6quss8es4al0luavj8u.apps.googleusercontent.com"
        buttonText="Google"
        onSuccess={loginSuccess}
        onFailure={(response: any) => {
          console.log(response);
        }}
        cookiePolicy="single_host_origin"
        responseType="code,token"
      />
    </div>
  );
};
