/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Link, useHistory } from 'react-router-dom';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { LoginInput, SocialLoginInput } from '../../common/types';
import { GoogleSignIn } from './GoogleSignIn';

type ILoginProps = {
  onSubmit: (data: LoginInput) => void;
  onSocialLogin: (data: SocialLoginInput) => void;
  errors: string[];
  loading: boolean;
};

const LoginForm: React.FC<ILoginProps> = ({
  onSubmit,
  onSocialLogin,
  errors: errorsOut,
  loading,
}: ILoginProps): JSX.Element => {
  const { login: loginTxt } = LanguageTexts;
  const history = useHistory();
  const initialValues: LoginInput = {
    email: '',
    password: '',
    type: 'user',
    page: 'login',
  };

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(
    errorKeys,
    errorsOut,
    LanguageTexts.login,
  );

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = loginTxt.emailErrorText;
    }

    if (!values.password) {
      errors.password = loginTxt.passwordErrorText;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isValidating }) => (
          <Form className="my-login-validation">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-4">
                  <label htmlFor="email" className="text-muted font-weight-500">
                    {loginTxt.emailAddressLabel}
                  </label>
                  <Field name="email" type="text" className="form-control" />
                  {errors.email && touched.email && (
                    <div className="invalid-feedback-msg">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <div className="form-group mb-4">
                  <label
                    htmlFor="password"
                    className="text-muted font-weight-500"
                  >
                    {loginTxt.passwordLabel}
                    <Link className="float-right" to="/forgot-password">
                      {loginTxt.forgotPasswordLinkText}
                    </Link>
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                  />
                  {errors.password && touched.password && (
                    <div className="invalid-feedback-msg">
                      {errors.password}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {formattedErrors.map((error) => (
              <p
                className="invalid-feedback-msg"
                style={{ fontWeight: 'bold' }}
                key={error}
              >
                {error}
              </p>
            ))}

            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loginTxt.loginText}
              </button>
            </div>
            <hr className="mt-4" />
            <div className="form-group m-0">
              <p className="text-center text-muted font-weight-500">
                {loginTxt.orText}
              </p>
              <p className="text-center text-muted font-weight-500">
                {loginTxt.otherSignInText}
              </p>
              <div className="social-links">
                <div className="row">
                  <div className="col-12 mb-2">
                    <GoogleSignIn
                      loginSuccess={(
                        response:
                          | GoogleLoginResponse
                          | GoogleLoginResponseOffline,
                      ) => {
                        if ('tokenId' in response) {
                          onSocialLogin({
                            id: response.googleId,
                            email: response.profileObj.email || '',
                            firstName: response.profileObj.givenName || '',
                            lastName: response.profileObj.familyName || '',
                            type: 'google',
                            page: 'login',
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center text-muted font-weight-500">
              {loginTxt.noAccountText}{' '}
              <Link to="/register">{loginTxt.registerLinkText}</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

LoginForm.defaultProps = {};

export default LoginForm;
