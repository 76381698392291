/* eslint-disable no-nested-ternary */
import React from 'react';

function BetStatusBadge({
  isWin,
  isRefundable,
}: {
  isWin: boolean;
  isRefundable: boolean;
}): JSX.Element {
  return (
    <span
      className={
        isWin === null
          ? ''
          : isWin || isRefundable
          ? 'badge badge-success text-5'
          : 'badge badge-danger text-5'
      }
      title={
        isWin === null ? '' : isRefundable ? 'Refund' : isWin ? 'Won' : 'Lost'
      }
    >
      {isWin === null ? '' : isRefundable ? 'R' : isWin ? 'W' : 'L'}
    </span>
  );
}

export default BetStatusBadge;
