import React from 'react';
import { useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';

const PersonalDetails: React.FC = (): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;

  const { user } = useSelector((state: RootState) => state.login);
  const { address } = useSelector((state: RootState) => state.profile);
  const { countries } = useSelector((state: RootState) => state.register);

  return (
    <>
      <div className="row mb-4">
        <p className="col-sm-2 text-muted text-sm-right">
          {profileTxt.nameText}
        </p>
        <p className="col-sm-10">
          {user?.firstName} {user?.lastName}
        </p>
      </div>
      <div className="row mb-4">
        <p className="col-sm-2 text-muted text-sm-right">
          {profileTxt.emailText}
        </p>
        <p className="col-sm-10">{user?.email}</p>
      </div>
      <div className="row mb-4">
        <p className="col-sm-2 text-muted text-sm-right">
          {profileTxt.addressText}
        </p>
        <p className="col-sm-10">
          {address?.streetAddress} {', '} {address?.city} {' - '} {address?.zip}
        </p>
      </div>
      <div className="row mb-4">
        <p className="col-sm-2 text-muted text-sm-right">
          {profileTxt.stateText}
        </p>
        <p className="col-sm-10">{address?.state}</p>
      </div>
      {address ? (
        <div className="row mb-4">
          <p className="col-sm-2 text-muted text-sm-right">
            {profileTxt.countryText}
          </p>
          <p className="col-sm-10">
            {countries &&
            countries.filter(({ _id }) => {
              return _id === address.countryId;
            }).length > 0 ? (
              <>
                {countries.map(({ name, _id }, i) => {
                  return _id === address.countryId ? (
                    <span key={`stateData${i}`}>{name}</span>
                  ) : (
                    ''
                  );
                })}
              </>
            ) : null}
          </p>
        </div>
      ) : null}
      <div className="row mb-4">
        <p className="col-sm-2 text-muted text-sm-right">
          {profileTxt.accountStatusText}
        </p>
        <p className="col-sm-10">
          <span className="text-5 bg-success text-white rounded-pill d-inline-block px-2 mb-0">
            <i className="fas fa-check-circle" /> {profileTxt.activeStatusText}
          </span>
        </p>
      </div>
    </>
  );
};

export default PersonalDetails;
