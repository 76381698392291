/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '../common/types';

const initialState: AppState = {
  loading: false,
  success: null,
  errors: [],
  filter: {
    centerId: null,
    batchId: null,
    childrenId: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});

export const {} = appSlice.actions;

export const { name: appSliceName, reducer: appSliceReducer } = appSlice;
