import React from 'react';

import CurrentBalancePage from '../account-details/current-balance/CurrentBalancePage';
import ProfilePhotoPage from '../profile-photo/ProfilePhotoPage';

const SidePanelPage: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-sm-6 col-12">
          <div className="card-box bg-light shadow-sm rounded text-center p-3 mb-4">
            <ProfilePhotoPage />
          </div>
        </div>
        <div className="col-lg-12 col-sm-6 col-12">
          <div className="card-box bg-light shadow-sm rounded text-center p-3 mb-4">
            <CurrentBalancePage />
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanelPage;
