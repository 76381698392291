import React from 'react';
import { useSelector } from 'react-redux';

import LanguageTexts from '../../../../common/language';
import { CardListModel, RootState } from '../../../../common/types';

type CreditCardDetailsProps = {
  data: CardListModel;
  handleSetDefaultPaymentMethod?: (id: string) => void;
  handleDeletePaymentMethod?: (id: string) => void;
};

const CreditCardDetails: React.FC<CreditCardDetailsProps> = ({
  data,
  handleSetDefaultPaymentMethod,
  handleDeletePaymentMethod,
}: CreditCardDetailsProps): JSX.Element => {
  const { account: accountTxt } = LanguageTexts;
  const {
    deletePaymentMethod: { loading: deletePaymentMethodLoading },
  } = useSelector((state: RootState) => state.profile);

  return (
    <>
      <div className="col-md-4 col-6 mb-5 credit-card-section">
        <div className="credit-card">
          <div className="top-card-section">
            <h2>
              <span className="account-card-expire text-uppercase text-5 d-block opacity-8 mb-1">
                {accountTxt.validUpto}
              </span>
              <span className="text-5">
                {data.upoData.ccExpMonth.length === 1
                  ? `0${data.upoData.ccExpMonth}`
                  : `${data.upoData.ccExpMonth}`}
                /{data.upoData.ccExpYear}
              </span>
            </h2>
            {
              data.upoData.brand === 'visa'
                ? <img src={`${process.env.REACT_APP_IMAGE_PATH}website/visa.png`} alt="brand" className="cc_brand" /> 
                : data.upoData.brand === 'master_card' 
                ? <img src={`${process.env.REACT_APP_IMAGE_PATH}website/mastercard.png`} alt="brand" className="cc_brand" /> 
                : data.upoData.brand === 'amex' 
                ? <img src={`${process.env.REACT_APP_IMAGE_PATH}website/amex.png`} alt="brand" className="cc_brand" />
                : <img src={`${process.env.REACT_APP_IMAGE_PATH}website/discover.png`} alt="brand" className="cc_brand" />
            }
          </div>
          <div className="bottom-card-section">
            <h2>{data.upoData.ccNameOnCard}</h2>
            <p>{data.upoData.ccCardNumber}</p>
          </div>
          <div className="credit-card-overlay rounded">
            {handleDeletePaymentMethod ? (
              <button
                type="button"
                className="text-light btn-link bg-transparent border-0"
                disabled={deletePaymentMethodLoading}
                onClick={() =>
                  handleDeletePaymentMethod(data.userPaymentOptionId)
                }
              >
                <i className="fas fa-trash-alt" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

CreditCardDetails.defaultProps = {
  handleSetDefaultPaymentMethod: undefined,
  handleDeletePaymentMethod: undefined,
};

export default CreditCardDetails;
