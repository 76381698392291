import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LanguageTexts from '../../common/language';
import { ForgotPasswordInput, RootState } from '../../common/types';
import { forgotPassword } from './forgot-password.slice';
import ForgotPasswordForm from './ForgotPasswordForm';
import { PublicRoutePath } from '../../common/constants';

const ForgotPasswordPage: React.FC = (): JSX.Element => {
  const { forgotPassword: forgotPasswordTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const history = useHistory();
  const { success, errors, loading } = useSelector(
    (state: RootState) => state.forgotPassword,
  );

  function onSubmit(data: ForgotPasswordInput) {
    dispatch(forgotPassword(data));
  }

  useEffect(() => {
    if (success) {
      toast.success(forgotPasswordTxt.successMsg, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push(PublicRoutePath.Login);
    }
  }, [success, forgotPasswordTxt, history]);

  return (
    <>
      <div className="main-container forgot-password-page"
        style={{
          background: `linear-gradient(0deg, #4d96f6f0 50%, #1a4eae8a 100%), url(${process.env.REACT_APP_IMAGE_PATH}website/bg-login.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="card-wrapper">
                <div className="card fat">
                  <div className="card-body">
                    <h3 className="text-10 font-weight-500 mb-5">
                      {forgotPasswordTxt.header}
                    </h3>
                    <ForgotPasswordForm
                      onSubmit={onSubmit}
                      errors={errors}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
