import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageTexts from '../../common/language';
import { LoginInput, RootState, SocialLoginInput } from '../../common/types';
import { login, socialLogin } from './login.slice';
import LoginForm from './LoginForm';

const LoginPage: React.FC = (): JSX.Element | null => {
  const { login: loginTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const { errors, loading } = useSelector((state: RootState) => state.login);

  function onSubmit(input: LoginInput) {
    dispatch(login(input));
  }

  function onSocialLogin(input: SocialLoginInput) {
    dispatch(socialLogin(input));
  }

  return (
    <>
      <div
        className="main-container login-page"
        style={{
          background: `linear-gradient(0deg, #4d96f6f0 50%, #1a4eae8a 100%), url(${process.env.REACT_APP_IMAGE_PATH}website/bg-login.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="card-wrapper">
                <div className="card fat">
                  <div className="card-body">
                    <h3 className="text-10 font-weight-500 mb-5">
                      {loginTxt.loginText}
                    </h3>
                    <LoginForm
                      onSubmit={onSubmit}
                      onSocialLogin={onSocialLogin}
                      errors={errors}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

LoginPage.defaultProps = {};

export default LoginPage;
