import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { loadSafeCharge } from '../../../../common/helper';
import LanguageTexts from '../../../../common/language';
import {
  AddCardInput,
  OpenOrderModel,
  RootState,
} from '../../../../common/types';
import {
  loadCardInfo,
  openPaymentOrder,
  resetOpenPaymentOrder,
} from '../../profile.slice';
import SidePanelPage from '../../side-panel/SidePanelPage';
import CreditCardForm from '../add-cards/CreditCardForm';
import DepositMoneyForm from './DepositMoneyForm';

const DepositMoneyPage: React.FC = (): JSX.Element => {
  const { account: accountTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [showDepMoneyForm, setShowDepMoneyForm] = useState(true);

  const [ccData, setCcData] = useState<any>();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [safeCharge, setSafeCharge] = useState<any>();

  const {
    openOrder,
    openPaymentOrder: { success: openPaymentOrderSuccess },
  } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    loadSafeCharge().then((safe) => {
      setSafeCharge(safe);
    });
  }, []);

  useEffect(() => {
    const createPayment = (data: OpenOrderModel) => {
      try {
        if (ccData && safeCharge) {
          safeCharge.createPayment(
            {
              sessionToken: data.sessionToken,
              merchantId: process.env.REACT_APP_MERCHANT_ID,
              merchantSiteId: process.env.REACT_APP_MERCHANT_SITE_ID,
              clientUniqueId: data.clientUniqueId,
              paymentOption: {
                card: {
                  cardNumber: ccData.ccCardNumber,
                  cardHolderName: ccData.ccNameOnCard,
                  expirationMonth: ccData.ccExpMonth,
                  expirationYear: ccData.ccExpYear,
                },
              },
            },
            (resp: any) => {
              // eslint-disable-next-line no-console
              console.log('pay res>', resp);
              dispatch(loadCardInfo());
              setCcData(undefined);
              setPaymentLoading(false);
              setEditMode(false);
              toast.success(accountTxt.addNewCardSuccess, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            },
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if (openPaymentOrderSuccess) {
      if (openOrder) {
        dispatch(resetOpenPaymentOrder());
        createPayment(openOrder);
      }
    }
  }, [
    openPaymentOrderSuccess,
    openOrder,
    dispatch,
    safeCharge,
    ccData,
    accountTxt,
  ]);

  function onSubmit(data: AddCardInput) {
    setCcData(data);
    setPaymentLoading(true);
    dispatch(openPaymentOrder({ amount: 0 }));
  }

  function onMoneyAdd() {
    setShowDepMoneyForm(false);
    setTimeout(() => setShowDepMoneyForm(true), 0);
    toast.success('Money added to wallet successfully', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <div id="main-wrapper" className="bg-primary gradient-color userpage">
        <div id="content" className="py-4 main-user-section">
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
            alt=""
            className="shape-1"
          />
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
            alt=""
            className="shape-2"
          />
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <SidePanelPage />
              </aside>
              <div className="col-lg-9">
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  {!editMode ? (
                    <>
                      <h3 className="text-8 font-weight-400 mb-4">
                        {accountTxt.addMoneyHeadingText}
                        {!editMode ? (
                          <button
                            type="button"
                            onClick={() => setEditMode(true)}
                            className="float-right text-5 text-uppercase text-muted btn-link border-0 bg-transparent"
                          >
                            <i className="fas fa-plus-circle mr-2" />
                            {accountTxt.addCardText}
                          </button>
                        ) : null}
                      </h3>
                      {!showDepMoneyForm || (
                        <DepositMoneyForm onMoneyAdd={onMoneyAdd} />
                      )}
                    </>
                  ) : (
                    <>
                      <h3 className="text-8 font-weight-400 mb-4">
                        {accountTxt.addCardText}
                      </h3>
                      <CreditCardForm
                        onSubmit={onSubmit}
                        errors={[]}
                        loading={paymentLoading}
                        onCancel={() => setEditMode(false)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositMoneyPage;
