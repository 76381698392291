import { Field, Form, Formik } from 'formik';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import useUserOngoingPools from '../../common/hooks';

import LanguageTexts from '../../common/language';
import { ContactUsInput, OpenOrderModel, RootState } from '../../common/types';
import PoolUsersList from '../../components/PoolUsersList';
import { allUserOngoingPools } from '../games/games.slice';
import { loadCardInfo, openPaymentOrder } from '../profile/profile.slice';
import ContactUsForm from './ContactUsForm';
import {
  addContactUs,
  loadPools,
  loadSportTypes,
  resetAddContactUsSuccess,
} from './home.slice';

const HomePage: React.FC = (): JSX.Element => {
  const { app: appTxt } = LanguageTexts;
  const { homePage: homeTxt } = LanguageTexts;
  const { contactUs: contactUsTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    pools,
    loading,
    addContactUs: {
      success: addContactUsSuccess,
      loading: addContactUsLoading,
      errors: addContactUsErrors,
    },
  } = useSelector((state: RootState) => state.home);
  const { allUserOngoingPools: allUserOngoingPoolsList } = useSelector(
    (state: RootState) => state.game,
  );

  const { sportTypes } = useSelector((state: RootState) => state.home);
  const [loadRequested, setLoadRequested] = useState(false);
  const [poolTab, setPoolTab] = useState('Daily');

  const allUserOngoingPoolsByPool = useUserOngoingPools(
    allUserOngoingPoolsList || [],
  );

  function onSubmit(data: ContactUsInput) {
    dispatch(addContactUs(data));
  }

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadPools());
      dispatch(loadSportTypes());
      dispatch(allUserOngoingPools());
      setLoadRequested(true);
    }
  }, [loadRequested, dispatch]);

  useEffect(() => {
    if (addContactUsSuccess) {
      toast.success(contactUsTxt.successMessage, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(resetAddContactUsSuccess());
    }
  }, [addContactUsSuccess, dispatch]);

  const addContactUsInit: ContactUsInput = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const changePoolTab = (sTab: string) => {
    setPoolTab(sTab);
  };

  return (
    <>
      <section
        id="hero"
        className="d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}website/hero-bg-img.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
        }}
      >
        <div
          id="heroCarousel"
          className="container carousel carousel-fade p-5"
          data-ride="carousel"
        >
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                {homeTxt.slide1Heading1} <br />
                <span className="font-weight-bold">
                  {homeTxt.slide1Heading2}
                </span>
              </h2>
              <p className="animate__animated animate__fadeInUp">
                {homeTxt.slide1Text1}
                <br /> {homeTxt.slide1Text2}
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                {homeTxt.slide2Heading}
              </h2>
              <p className="animate__animated animate__fadeInUp">
                {homeTxt.dummyText}
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                {homeTxt.slide3Heading}
              </h2>
              <p className="animate__animated animate__fadeInUp">
                {homeTxt.dummyText}
              </p>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon bx" aria-hidden="true">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}website/left-arrow.png`}
                alt=""
                width="45"
              />
            </span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}website/right-arrow.png`}
                alt=""
                width="45"
              />
            </span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </section>
      <main id="main">
        <section
          id="icon-boxes"
          className="icon-boxes bg-primary gradient-color"
        >
          <div className="shape">
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
              alt=""
              className="shape-1"
            />
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
              alt=""
              className="shape-2"
            />
          </div>
          <div className="container">
            <div className="row justify-content-center text-center">
              {sportTypes &&
                sportTypes.length > 0 &&
                sportTypes.map((sportType) => {
                  return (
                    <div
                      className={
                        sportType.comingSoon
                          ? 'col-md-2 col-3 icon-box-col mb-4'
                          : 'col-md-2 col-3 icon-box-col active mb-4'
                      }
                      key={`sp-type-${sportType._id}`}
                    >
                      {sportType.comingSoon ? (
                        <div className="icon-overlay">
                          <span>{homeTxt.comingSoonText}</span>
                        </div>
                      ) : null}
                      <div className="icon-box">
                        <span>
                          <i className={sportType.icon} />
                        </span>
                      </div>
                      <h4 className="title text-uppercase mt-3">
                        {sportType.name}
                      </h4>
                    </div>
                  );
                })}
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <div className="pricing card-deck flex-column flex-md-row mb-3">
                      <div className="row">
                        {pools &&
                          pools.length > 0 &&
                          pools.map((pool) => {
                            return pool.name !== 'Daily Pool (India)' &&
                              pool.name !== 'Extended Pool (India)' &&
                              pool.name !== 'Daily Pool (Jayshree)' &&
                              pool.name !== 'Extended Pool(Jayshree)' &&
                              pool.name !== 'Daily Pool (Sunny)' &&
                              pool.name !== 'Extended Pool (Sunny)' ? (
                              <div className="col-md-6 col-12">
                                <div
                                  key={`pool-big-${pool.value}`}
                                  className="card card-pricing text-center px-3 mb-5"
                                >
                                  <span className="h6 w-100 mx-auto px-4 py-4 rounded-bottom bg-primary text-white font-weight-600 shadow-sm">
                                    {pool.name}
                                  </span>
                                  <div className="bg-transparent card-header pt-5 border-0">
                                    <h1
                                      className="h1 font-weight-normal text-primary text-center mb-4"
                                      data-pricing-value="30"
                                    >
                                      {appTxt.currencySymbol}
                                      <span className="price">
                                        {Number(pool.amount.toFixed(0))
                                          .toLocaleString()
                                          .split(/\s/)
                                          .join(',')}
                                      </span>
                                      <span className="h6 primary-color d-block ml-2">
                                        {homeTxt.winningAmountText}
                                      </span>
                                    </h1>
                                  </div>
                                  <hr />
                                  <div className="card-body pt-4">
                                    <ul className="pooldetail-list list-unstyled mb-5">
                                      <li>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: LanguageTexts.homePage.entryText.replace(
                                              '{pool_entry}',
                                              `${Number(pool.entry.toFixed(0))
                                                .toLocaleString()
                                                .split(/\s/)
                                                .join(',')}`,
                                            ),
                                          }}
                                          className="mb-4"
                                        />
                                      </li>
                                      <li>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: LanguageTexts.homePage.maxPlayersText.replace(
                                              '{pool_maxPlayer}',
                                              `${pool.maxPlayer}`,
                                            ),
                                          }}
                                          className="mb-4"
                                        />
                                      </li>
                                      <li>
                                        <span className="primary-color">
                                          Get
                                        </span>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: LanguageTexts.homePage.coinsNumberText.replace(
                                              '{pool_coins}',
                                              `${Number(pool.coins.toFixed(0))
                                                .toLocaleString()
                                                .split(/\s/)
                                                .join(',')}`,
                                            ),
                                          }}
                                          className="mb-4 primary-color"
                                        />
                                      </li>
                                    </ul>
                                    <a
                                      href={`/games/${pool.value}`}
                                      className="btn btn-primary mb-4"
                                    >
                                      {homeTxt.playNowBtnText}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ) : null;
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 pool-top-scorer-table">
                    <div className="list-group list-group-flush border-radius-10 winners-list-group bg-black">
                      <div
                        id="leading-scorer-header"
                        className="leading-scorer-header d-flex align-items-center justify-content-start"
                      >
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: LanguageTexts.games.leadingScorerHeading.replace(
                              '{pool_name}',
                              '',
                            ),
                          }}
                          className="winner-title mb-0"
                        />
                      </div>
                      <div className="winners-list pt-4">
                        <div
                          id="carouselTopScorer"
                          className="carousel slide"
                          data-ride="carousel"
                          data-interval={3000}
                        >
                          <div className="carousel-inner">
                            {pools &&
                              pools.length > 0 &&
                              pools.map((pool, pKey) => {
                                return pool.name !== '' ? (
                                  <div
                                    className={
                                      pKey === 0
                                        ? 'carousel-item active'
                                        : 'carousel-item'
                                    }
                                    key={`pool-${pool.value}`}
                                  >
                                    <h3 className="text-center">{pool.name}</h3>
                                    {allUserOngoingPoolsByPool &&
                                    allUserOngoingPoolsByPool[pool.value] ? (
                                      <PoolUsersList
                                        users={
                                          allUserOngoingPoolsByPool[pool.value]
                                            .usersByRank
                                        }
                                      />
                                    ) : (
                                      <p style={{ textAlign: 'center' }}>
                                          {appTxt.noRecordsFound}
                                      </p>
                                    )}
                                  </div>
                                ) : null;
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="carousel-control-arrow">
                        <a
                          className="carousel-control-prev"
                          href="#carouselTopScorer"
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-icons"
                            aria-hidden="true"
                          >
                            <i className="fa fa-angle-left" />
                          </span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href="#carouselTopScorer"
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-icons"
                            aria-hidden="true"
                          >
                            <i className="fa fa-angle-right" />
                          </span>
                          <span className="sr-only">Next</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="clients" className="work">
          <div className="shape">
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}website/shape03-bg.png`}
              alt=""
              className="shape-3"
            />
          </div>
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-sm-12 col-md-8 order-2 order-md-1">
                <div className="bd-example mb-5">
                  <div
                    id="carouselExampleCaptions"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}website/work-img.jpg`}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}website/work-img.jpg`}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}website/work-img.jpg`}
                          className="d-block w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="carousel-control-arrow">
                      <a
                        className="carousel-control-prev"
                        href="#carouselExampleCaptions"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          className="carousel-control-icons"
                          aria-hidden="true"
                        >
                          <i className="fa fa-angle-left" />
                        </span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carouselExampleCaptions"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          className="carousel-control-icons"
                          aria-hidden="true"
                        >
                          <i className="fa fa-angle-right" />
                        </span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 order-1 order-md-2 d-flex">
                <div className="row">
                  <div className="col-sm-12 d-flex mb-sm-2 mb-0">
                    <div className="section-title d-flex">
                      <h2>
                        {homeTxt.howItWorkHeading1}
                        <br />
                        {homeTxt.howItWorkHeading2}
                      </h2>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="carousel-caption carousel-text">
                      <p className="px-3 m-0">{homeTxt.dummyText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact p-0">
          <div className="container-fluid bg-light" data-aos="fade-up">
            <div className="shape">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
                alt=""
                className="shape-4"
              />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 bg-light mb-0 py-1 px-5">
                  <div className="section-title contact-us">
                    <h2>{homeTxt.contactUsHeading}</h2>
                  </div>
                  <div
                    className="row d-flex justify-content-end"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <div
                      className="col-lg-12"
                      data-aos="fade-left"
                      data-aos-delay="100"
                    >
                      {!addContactUsSuccess ? (
                        <ContactUsForm
                          onSubmit={onSubmit}
                          errors={addContactUsErrors}
                          loading={addContactUsLoading}
                          initialValues={addContactUsInit}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;
