/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CountryModel,
  RegisterInput,
  RegisterState,
  StateModel,
  UserModel,
} from '../../common/types';

const initialState: RegisterState = {
  loading: false,
  success: null,
  errors: [],
  user: null,
  userCheckDone: false,
  countries: null,
  states: null,
  countryState: {
    loading: false,
    success: null,
    errors: [],
  },
  provinceState: {
    loading: false,
    success: null,
    errors: [],
  },
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    register(state, action: PayloadAction<RegisterInput>) {
      state.loading = true;
      state.success = null;
      state.errors = [];

      state.user = null;
    },
    registerSuccess(state, action: PayloadAction<UserModel>) {
      state.loading = false;
      state.success = true;
    },
    registerError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCountries(state, action: PayloadAction) {
      state.countryState.loading = true;
      state.countryState.success = null;
      state.countryState.errors = [];
    },
    loadCountriesSuccess(state, action: PayloadAction<CountryModel[]>) {
      state.countryState.loading = false;
      state.countries = action.payload;
      state.countryState.success = !!action.payload;
    },
    loadCountriesError(state, action: PayloadAction<string[]>) {
      state.countryState.loading = false;
      state.countryState.errors = action.payload;
    },
    loadStates(state, action: PayloadAction<string>) {
      state.provinceState.loading = true;
      state.provinceState.success = null;
      state.provinceState.errors = [];
    },
    loadStatesSuccess(state, action: PayloadAction<StateModel[]>) {
      state.provinceState.loading = false;
      state.states = action.payload;
      state.provinceState.success = !!action.payload;
    },
    loadStatesError(state, action: PayloadAction<string[]>) {
      state.provinceState.loading = false;
      state.provinceState.errors = action.payload;
    },
  },
});

export const {
  register,
  registerSuccess,
  registerError,
  loadCountries,
  loadCountriesSuccess,
  loadCountriesError,
  loadStates,
  loadStatesSuccess,
  loadStatesError,
} = registerSlice.actions;

export const {
  name: registerSliceName,
  reducer: registerSliceReducer,
} = registerSlice;
