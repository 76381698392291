import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import {
  forgotPasswordSliceName,
  forgotPasswordSliceReducer,
} from '../features/forgot-password/forgot-password.slice';
import { gameSliceName, gameSliceReducer } from '../features/games/games.slice';
import { homeSliceName, homeSliceReducer } from '../features/home/home.slice';
import {
  loginSliceName,
  loginSliceReducer,
} from '../features/login/login.slice';
import {
  profileSliceName,
  profileSliceReducer,
} from '../features/profile/profile.slice';
import {
  registerSliceName,
  registerSliceReducer,
} from '../features/register/register.slice';
import { appSliceName, appSliceReducer } from './app.slice';
import history from './history';

const router = { router: connectRouter(history) };

const login = { [loginSliceName]: loginSliceReducer };
const register = { [registerSliceName]: registerSliceReducer };
const forgotPassword = {
  [forgotPasswordSliceName]: forgotPasswordSliceReducer,
};
const game = { [gameSliceName]: gameSliceReducer };
const home = { [homeSliceName]: homeSliceReducer };
const profile = { [profileSliceName]: profileSliceReducer };

const app = {
  [appSliceName]: appSliceReducer,
};

export const rootReducer = combineReducers({
  ...router,
  ...login,
  ...register,
  ...forgotPassword,
  ...app,
  ...profile,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    ...injectedReducers,
    ...router,
    ...login,
    ...register,
    ...forgotPassword,
    ...game,
    ...home,
    ...profile,
    ...app,
  });
}

export type RootState = ReturnType<typeof rootReducer>;
