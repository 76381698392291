import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LanguageTexts from '../../../common/language';
import { RootState, UpdateProfileInput } from '../../../common/types';
import { whoami } from '../../login/login.slice';
import { loadCountries } from '../../register/register.slice';
import {
  resetUpdateProfileSuccess,
  showAddress,
  updateProfile,
} from '../profile.slice';
import PersonalDetails from './PersonalDetails';
import PersonalDetailsForm from './PersonalDetailsForm';

const PersonalDetailsPage: React.FC = (): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const {
    updateProfile: { loading, success },
  } = useSelector((state: RootState) => state.profile);
  const { countries } = useSelector((state: RootState) => state.register);
  const [loadRequested, setLoadRequested] = useState(false);

  const [editMode, setEditMode] = useState(false);

  function onSubmit(data: UpdateProfileInput) {
    dispatch(updateProfile(data));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetUpdateProfileSuccess());
      dispatch(whoami());
      dispatch(showAddress());
      toast.success(profileTxt.updateProfileSuccess, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setEditMode(false);
    }
  }, [success, dispatch]);

  useEffect(() => {
    dispatch(showAddress());
  }, [dispatch]);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadCountries());
      setLoadRequested(true);
    }
  }, [loadRequested, dispatch]);

  if (!editMode) {
    return (
      <>
        <h3 className="text-8 font-weight-400 mb-4">
          {profileTxt.personalDetailsHeading}
          <button
            type="button"
            onClick={() => setEditMode(true)}
            className="float-right text-5 text-uppercase text-muted btn-link border-0 bg-transparent"
          >
            <i className="fas fa-edit mr-2" />
            {profileTxt.editLinkText}
          </button>
        </h3>
        <PersonalDetails />
      </>
    );
  }

  return (
    <>
      <h3 className="text-8 font-weight-400 mb-4">
        {profileTxt.editDetailsHeading}
      </h3>
      <PersonalDetailsForm
        onSubmit={onSubmit}
        onCancel={() => setEditMode(false)}
        loading={loading}
        countries={countries || []}
      />
    </>
  );
};

export default PersonalDetailsPage;
