import React from 'react';

import { BetTypes, TeamTypes } from '../common/constants';

const UserBetDisplay = ({
  type,
  bet,
  teamType,
}: {
  type: string;
  bet: any;
  teamType: string;
}): JSX.Element => {
  if (type === BetTypes.PS) {
    return teamType === TeamTypes.HOME ? (
      <div>
        {(bet.homePointSpread < 0 ? '' : '+') + bet.homePointSpread} (
        {(bet.homePointSpreadPayout < 0 ? '' : '+') + bet.homePointSpreadPayout}
        )
      </div>
    ) : (
      <div>
        {(bet.awayPointSpread < 0 ? '' : '+') + bet.awayPointSpread} (
        {(bet.awayPointSpreadPayout < 0 ? '' : '+') + bet.awayPointSpreadPayout}
        )
      </div>
    );
  }
  if (type === BetTypes.ML) {
    return teamType === TeamTypes.HOME ? (
      <div>{(bet.homeMoneyLine < 0 ? '' : '+') + bet.homeMoneyLine}</div>
    ) : (
      <div>{(bet.awayMoneyLine < 0 ? '' : '+') + bet.awayMoneyLine}</div>
    );
  }
  return teamType === TeamTypes.HOME ? (
    <div>
      OV: {bet.overUnder} ({(bet.overPayout < 0 ? '' : '+') + bet.overPayout})
    </div>
  ) : (
    <div>
      UN: {bet.overUnder} ({(bet.underPayout < 0 ? '' : '+') + bet.underPayout})
    </div>
  );
};

export default UserBetDisplay;
