import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import { checkLoginOTP, resetCheckLoginOTPSuccess } from './login.slice';

const LoginVerifyOtpForm: React.FC = (): JSX.Element => {
  const { login: loginTxt, profile: profileTxt } = LanguageTexts;

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    checkLoginOTP: {
      loading: checkLoginOTPLoading,
      success: checkLoginOTPSuccess,
      errors: checkLoginOTPErrors,
    },
  } = useSelector((state: RootState) => state.login);

  const userData: any = history.location.state;

  const initialValues = {
    token: '',
  };
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.token) {
      errors.token = 'OTP is required.';
    }

    return errors;
  };

  useEffect(() => {
    if (checkLoginOTPSuccess) {
      dispatch(resetCheckLoginOTPSuccess());
    } else if (checkLoginOTPErrors && checkLoginOTPErrors.length > 0) {
      toast.error(checkLoginOTPErrors[0], {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [checkLoginOTPSuccess, checkLoginOTPErrors, dispatch, history, userData]);

  function onSubmit(token: string) {
    dispatch(
      checkLoginOTP({
        input: { token: parseInt(token, 10) || 0 },
        access_token: userData.access_token,
      }),
    );
  }

  if (!userData) {
    return <p>Something went wrong.</p>;
  }

  return (
    <div
      className="main-container login-page"
      style={{
        background: `linear-gradient(0deg, #4d96f6f0 50%, #1a4eae8a 100%), url(${process.env.REACT_APP_IMAGE_PATH}website/bg-login.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    >
      <section className="h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="card-wrapper">
              <div className="card fat">
                <div className="card-body">
                  <h3 className="text-10 font-weight-500 mb-5">
                    {loginTxt.verifyHeading}
                  </h3>
                  <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={(values, { setSubmitting }) => {
                      onSubmit(values.token);
                      setSubmitting(false);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form className="my-login-validation">
                        <div className="row d-lg-flex align-items-center">
                          <div className="col-12">
                            <div className="form-group mb-4">
                              <label
                                htmlFor="token"
                                className="text-muted font-weight-500"
                              >
                                {profileTxt.otpLabel}
                              </label>
                              <Field
                                name="token"
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        {errors.token && touched.token && (
                          <div className="invalid-feedback-msg">
                            {errors.token}
                          </div>
                        )}
                        <div className="form-group mt-4">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={checkLoginOTPLoading}
                          >
                            {loginTxt.verifyBtn}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginVerifyOtpForm;
