/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AddMoneyToWalletInput,
  AddressModel,
  CardModel,
  ChangeDefaultCardInput,
  ChangePasswordInput,
  DeductMoneyFromWalletInput,
  OpenOrderModel,
  OpenPaymentOrderInput,
  ProfileState,
  UpdateProfileInput,
  UpdateProfilePicInput,
  UserBetModel,
  UserModel,
  UserPoolOngoingModel,
  VerifyMobileInput,
  VerifyTokenInput,
  WithdrawalRequestModel,
  WithdrawURLModel,
} from '../../common/types';

const initialState: ProfileState = {
  loading: false,
  success: null,
  errors: [],
  showProfilePic: null,
  openOrder: null,
  uploadProfilePic: {
    loading: false,
    success: null,
    errors: [],
  },
  changePassword: {
    loading: false,
    success: null,
    errors: [],
  },
  openPaymentOrder: {
    loading: false,
    success: null,
    errors: [],
  },
  withdrawUrl: null,
  generateWithdrawUrl: {
    loading: false,
    success: null,
    errors: [],
  },
  withdrawalRequests: null,
  withdrawalRequestState: {
    loading: false,
    success: null,
    errors: [],
  },
  showProfile: null,
  updateProfile: {
    loading: false,
    success: null,
    errors: [],
  },
  address: null,
  addressState: {
    loading: false,
    success: null,
    errors: [],
  },
  userBets: null,
  userBetState: {
    loading: false,
    success: null,
    errors: [],
  },
  myPoolBets: null,
  myPoolBetsState: {
    loading: false,
    success: null,
    errors: [],
  },
  userCardInfo: null,
  addCardInfo: {
    loading: false,
    success: null,
    errors: [],
  },
  setDefaultPaymentMethod: {
    loading: false,
    success: null,
    errors: [],
  },
  deletePaymentMethod: {
    loading: false,
    success: null,
    errors: [],
  },
  addMoneyToWalletState: {
    loading: false,
    success: null,
    errors: [],
  },
  deductMoneyFromWalletState: {
    loading: false,
    success: null,
    errors: [],
  },
  sendTokenToVerifyMobile: {
    loading: false,
    success: null,
    errors: [],
  },
  checkTokenToVerifyMobile: {
    loading: false,
    success: null,
    errors: [],
  },
  disableTwoFA: {
    loading: false,
    success: null,
    errors: [],
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateProfilePic(state, action: PayloadAction<UpdateProfilePicInput>) {
      state.uploadProfilePic.loading = true;
      state.uploadProfilePic.errors = [];
    },
    updateProfilePicSuccess(state, action: PayloadAction<UserModel>) {
      state.uploadProfilePic.loading = false;
      state.uploadProfilePic.success = !!action.payload;
      state.showProfilePic = action.payload;
    },
    updateProfilePicError(state, action: PayloadAction<string[]>) {
      state.uploadProfilePic.loading = false;
      state.uploadProfilePic.errors = action.payload;
    },
    resetUpdateProfilePicSuccess(state) {
      state.uploadProfilePic.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateProfile(state, action: PayloadAction<UpdateProfileInput>) {
      state.updateProfile.loading = true;
      state.updateProfile.errors = [];
    },
    updateProfileSuccess(state, action: PayloadAction<UserModel>) {
      state.updateProfile.loading = false;
      state.updateProfile.success = !!action.payload;
      state.showProfile = action.payload;
    },
    updateProfileError(state, action: PayloadAction<string[]>) {
      state.updateProfile.loading = false;
      state.updateProfile.errors = action.payload;
    },
    resetUpdateProfileSuccess(state) {
      state.updateProfile.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changePassword(state, action: PayloadAction<ChangePasswordInput>) {
      state.changePassword.loading = true;
      state.changePassword.errors = [];
    },
    changePasswordSuccess(state, action: PayloadAction<boolean>) {
      state.changePassword.loading = false;
      state.changePassword.success = action.payload;
    },
    changePasswordError(state, action: PayloadAction<string[]>) {
      state.changePassword.loading = false;
      state.changePassword.errors = action.payload;
    },
    resetChangePassword(state) {
      state.changePassword.success = null;
      state.changePassword.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showAddress(state, action: PayloadAction) {
      state.addressState.loading = true;
      state.addressState.success = false;
      state.addressState.errors = [];
    },
    showAddressSuccess(state, action: PayloadAction<AddressModel>) {
      state.addressState.loading = false;
      state.addressState.success = true;
      state.address = action.payload;
    },
    showAddressError(state, action: PayloadAction<string[]>) {
      state.addressState.loading = false;
      state.addressState.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadCardInfo(state, action: PayloadAction<undefined>) {
      state.loading = true;
      state.errors = [];
    },
    loadCardInfoSuccess(state, action: PayloadAction<CardModel>) {
      state.loading = false;
      state.success = !!action.payload;
      state.userCardInfo = action.payload;
    },
    loadCardInfoError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadCardInfo(state) {
      state.success = null;
      state.errors = [];
    },
    setDefaultPaymentMethod(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ChangeDefaultCardInput>,
    ) {
      state.setDefaultPaymentMethod.loading = true;
      state.setDefaultPaymentMethod.errors = [];
    },
    setDefaultPaymentMethodSuccess(state, action: PayloadAction<boolean>) {
      state.setDefaultPaymentMethod.loading = false;
      state.setDefaultPaymentMethod.success = !!action.payload;
    },
    setDefaultPaymentMethodError(state, action: PayloadAction<string[]>) {
      state.setDefaultPaymentMethod.loading = false;
      state.setDefaultPaymentMethod.errors = action.payload;
    },
    resetSetDefaultPaymentMethod(state) {
      state.setDefaultPaymentMethod.success = null;
      state.setDefaultPaymentMethod.errors = [];
    },
    deletePaymentMethod(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ChangeDefaultCardInput>,
    ) {
      state.deletePaymentMethod.loading = true;
      state.deletePaymentMethod.errors = [];
    },
    deletePaymentMethodSuccess(state, action: PayloadAction<boolean>) {
      state.deletePaymentMethod.loading = false;
      state.deletePaymentMethod.success = !!action.payload;
    },
    deletePaymentMethodError(state, action: PayloadAction<string[]>) {
      state.deletePaymentMethod.loading = false;
      state.deletePaymentMethod.errors = action.payload;
    },
    resetDeletePaymentMethod(state) {
      state.deletePaymentMethod.success = null;
      state.deletePaymentMethod.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUserBets(state, action: PayloadAction<any>) {
      state.userBets = null;
      state.userBetState.loading = true;
      state.userBetState.errors = [];
    },
    loadUserBetsSuccess(state, action: PayloadAction<UserBetModel[]>) {
      state.userBetState.loading = false;
      state.userBetState.success = !!action.payload;
      state.userBets = action.payload;
    },
    loadUserBetsError(state, action: PayloadAction<string[]>) {
      state.userBetState.loading = false;
      state.userBetState.errors = action.payload;
    },
    resetUserBets(state) {
      state.userBetState.success = null;
      state.userBetState.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadMyPoolBets(state, action: PayloadAction<any>) {
      state.myPoolBets = null;
      state.myPoolBetsState.loading = true;
      state.myPoolBetsState.errors = [];
    },
    loadMyPoolBetsSuccess(
      state,
      action: PayloadAction<UserPoolOngoingModel[]>,
    ) {
      state.myPoolBetsState.loading = false;
      state.myPoolBetsState.success = !!action.payload;
      state.myPoolBets = action.payload;
    },
    loadMyPoolBetsError(state, action: PayloadAction<string[]>) {
      state.myPoolBetsState.loading = false;
      state.myPoolBetsState.errors = action.payload;
    },
    resetLoadMyPoolBets(state) {
      state.myPoolBetsState.success = null;
      state.myPoolBetsState.errors = [];
    },
    openPaymentOrder(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<OpenPaymentOrderInput>,
    ) {
      state.openPaymentOrder.loading = true;
      state.openPaymentOrder.errors = [];
    },
    openPaymentOrderSuccess(state, action: PayloadAction<OpenOrderModel>) {
      state.openPaymentOrder.loading = false;
      state.openPaymentOrder.success = !!action.payload;
      state.openOrder = action.payload;
    },
    openPaymentOrderError(state, action: PayloadAction<string[]>) {
      state.openPaymentOrder.loading = false;
      state.openPaymentOrder.errors = action.payload;
    },
    resetOpenPaymentOrder(state) {
      state.openPaymentOrder.success = null;
      state.openPaymentOrder.errors = [];
    },
    generateWithdrawUrl(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<OpenPaymentOrderInput>,
    ) {
      state.generateWithdrawUrl.loading = true;
      state.generateWithdrawUrl.errors = [];
    },
    generateWithdrawUrlSuccess(state, action: PayloadAction<WithdrawURLModel>) {
      state.generateWithdrawUrl.loading = false;
      state.generateWithdrawUrl.success = !!action.payload;
      state.withdrawUrl = action.payload;
    },
    generateWithdrawUrlError(state, action: PayloadAction<string[]>) {
      state.generateWithdrawUrl.loading = false;
      state.generateWithdrawUrl.errors = action.payload;
    },
    resetGenerateWithdrawUrl(state) {
      state.generateWithdrawUrl.success = null;
      state.generateWithdrawUrl.errors = [];
    },
    getWithdrawalRequests(state) {
      state.withdrawalRequestState.loading = true;
      state.withdrawalRequestState.errors = [];
    },
    getWithdrawalRequestsSuccess(
      state,
      action: PayloadAction<WithdrawalRequestModel[]>,
    ) {
      state.withdrawalRequestState.loading = false;
      state.withdrawalRequestState.success = !!action.payload;
      state.withdrawalRequests = action.payload;
    },
    getWithdrawalRequestsError(state, action: PayloadAction<string[]>) {
      state.withdrawalRequestState.loading = false;
      state.withdrawalRequestState.errors = action.payload;
    },
    resetGetWithdrawalRequests(state) {
      state.withdrawalRequestState.success = null;
      state.withdrawalRequestState.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addMoneyToWallet(state, action: PayloadAction<AddMoneyToWalletInput>) {
      state.addMoneyToWalletState.loading = true;
      state.addMoneyToWalletState.errors = [];
    },
    addMoneyToWalletSuccess(state, action: PayloadAction<boolean>) {
      state.addMoneyToWalletState.loading = false;
      state.addMoneyToWalletState.success = !!action.payload;
    },
    addMoneyToWalletError(state, action: PayloadAction<string[]>) {
      state.addMoneyToWalletState.loading = false;
      state.addMoneyToWalletState.errors = action.payload;
    },
    resetAddMoneyToWalletSuccess(state) {
      state.addMoneyToWalletState.success = null;
    },
    deductMoneyFromWallet(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<DeductMoneyFromWalletInput>,
    ) {
      state.deductMoneyFromWalletState.loading = true;
      state.deductMoneyFromWalletState.errors = [];
    },
    deductMoneyFromWalletSuccess(state, action: PayloadAction<boolean>) {
      state.deductMoneyFromWalletState.loading = false;
      state.deductMoneyFromWalletState.success = !!action.payload;
    },
    deductMoneyFromWalletError(state, action: PayloadAction<string[]>) {
      state.deductMoneyFromWalletState.loading = false;
      state.deductMoneyFromWalletState.errors = action.payload;
    },
    resetDeductMoneyFromWalletSuccess(state) {
      state.deductMoneyFromWalletState.success = null;
    },
    sendTokenToVerifyMobile(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<VerifyMobileInput>,
    ) {
      state.sendTokenToVerifyMobile.loading = true;
      state.sendTokenToVerifyMobile.errors = [];
    },
    sendTokenToVerifyMobileSuccess(state, action: PayloadAction<boolean>) {
      state.sendTokenToVerifyMobile.loading = false;
      state.sendTokenToVerifyMobile.success = !!action.payload;
    },
    sendTokenToVerifyMobileError(state, action: PayloadAction<string[]>) {
      state.sendTokenToVerifyMobile.loading = false;
      state.sendTokenToVerifyMobile.errors = action.payload;
    },
    resetSendTokenToVerifyMobileSuccess(state) {
      state.sendTokenToVerifyMobile.success = null;
    },
    checkTokenToVerifyMobile(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<VerifyTokenInput>,
    ) {
      state.checkTokenToVerifyMobile.loading = true;
      state.checkTokenToVerifyMobile.errors = [];
    },
    checkTokenToVerifyMobileSuccess(state, action: PayloadAction<boolean>) {
      state.checkTokenToVerifyMobile.loading = false;
      state.checkTokenToVerifyMobile.success = !!action.payload;
    },
    checkTokenToVerifyMobileError(state, action: PayloadAction<string[]>) {
      state.checkTokenToVerifyMobile.loading = false;
      state.checkTokenToVerifyMobile.errors = action.payload;
    },
    resetCheckTokenToVerifyMobileSuccess(state) {
      state.checkTokenToVerifyMobile.success = null;
    },
    disableTwoFA(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<undefined>,
    ) {
      state.disableTwoFA.loading = true;
      state.disableTwoFA.errors = [];
    },
    disableTwoFASuccess(state, action: PayloadAction<boolean>) {
      state.disableTwoFA.loading = false;
      state.disableTwoFA.success = !!action.payload;
    },
    disableTwoFAError(state, action: PayloadAction<string[]>) {
      state.disableTwoFA.loading = false;
      state.disableTwoFA.errors = action.payload;
    },
    resetDisableTwoFASuccess(state) {
      state.disableTwoFA.success = null;
    },
  },
});

export const {
  updateProfilePic,
  updateProfilePicSuccess,
  updateProfilePicError,
  resetUpdateProfilePicSuccess,
  updateProfile,
  updateProfileError,
  updateProfileSuccess,
  resetUpdateProfileSuccess,
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  resetChangePassword,
  showAddress,
  showAddressSuccess,
  showAddressError,
  loadCardInfo,
  loadCardInfoSuccess,
  loadCardInfoError,
  loadUserBets,
  loadUserBetsSuccess,
  loadUserBetsError,
  resetUserBets,
  loadMyPoolBets,
  loadMyPoolBetsSuccess,
  loadMyPoolBetsError,
  resetLoadMyPoolBets,
  resetLoadCardInfo,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodSuccess,
  setDefaultPaymentMethodError,
  resetSetDefaultPaymentMethod,
  deletePaymentMethod,
  deletePaymentMethodSuccess,
  deletePaymentMethodError,
  resetDeletePaymentMethod,
  openPaymentOrder,
  openPaymentOrderSuccess,
  openPaymentOrderError,
  resetOpenPaymentOrder,
  generateWithdrawUrl,
  generateWithdrawUrlSuccess,
  generateWithdrawUrlError,
  resetGenerateWithdrawUrl,
  getWithdrawalRequests,
  getWithdrawalRequestsSuccess,
  getWithdrawalRequestsError,
  resetGetWithdrawalRequests,
  addMoneyToWallet,
  addMoneyToWalletSuccess,
  addMoneyToWalletError,
  resetAddMoneyToWalletSuccess,
  deductMoneyFromWallet,
  deductMoneyFromWalletSuccess,
  deductMoneyFromWalletError,
  resetDeductMoneyFromWalletSuccess,
  sendTokenToVerifyMobile,
  sendTokenToVerifyMobileSuccess,
  sendTokenToVerifyMobileError,
  resetSendTokenToVerifyMobileSuccess,
  checkTokenToVerifyMobile,
  checkTokenToVerifyMobileSuccess,
  checkTokenToVerifyMobileError,
  resetCheckTokenToVerifyMobileSuccess,
  disableTwoFA,
  disableTwoFASuccess,
  disableTwoFAError,
  resetDisableTwoFASuccess,
} = profileSlice.actions;

export const {
  name: profileSliceName,
  reducer: profileSliceReducer,
} = profileSlice;
