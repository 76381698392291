import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { TransactionType } from '../../../../common/constants';
import { loadSafeCharge } from '../../../../common/helper';
import LanguageTexts from '../../../../common/language';
import { OpenOrderModel, RootState } from '../../../../common/types';
import {
  resetTransactionCallbackSuccess,
  transactionCallback,
} from '../../../games/games.slice';
import { whoami } from '../../../login/login.slice';
import {
  addMoneyToWallet,
  loadCardInfo,
  openPaymentOrder,
  resetAddMoneyToWalletSuccess,
  resetOpenPaymentOrder,
} from '../../profile.slice';

type DepositMoneyArgs = {
  onMoneyAdd: () => void;
};

const DepositMoneyForm: React.FC<DepositMoneyArgs> = ({
  onMoneyAdd,
}: DepositMoneyArgs): JSX.Element => {
  const { account: accountTxt, app: appTxt } = LanguageTexts;
  const dispatch = useDispatch();

  // local state
  const [loadRequested, setLoadRequested] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>();
  const [safeCharge, setSafeCharge] = useState<any>();
  const [cvv, setCvv] = useState();
  const [loading, setLoading] = useState(false);

  // global state
  const {
    userCardInfo,
    openOrder,
    openPaymentOrder: { success: openPaymentOrderSuccess },
    addMoneyToWalletState: { success: addMoneyToWalletSuccess },
  } = useSelector((state: RootState) => state.profile);
  const {
    transaction,
    transactionCallbackState: { success: transactionCallbackSuccess },
  } = useSelector((state: RootState) => state.game);

  useEffect(() => {
    loadSafeCharge().then((safe) => {
      setSafeCharge(safe);
    });
  }, []);

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadCardInfo());
      setLoadRequested(true);
    }
  }, [loadRequested, dispatch]);

  // payment stuff
  let cards: any = [];
  if (userCardInfo) {
    cards = userCardInfo.paymentMethods.map((item) => {
      return {
        value: item.userPaymentOptionId,
        label: item.upoData.ccCardNumber,
      };
    });
  }

  useEffect(() => {
    const createPayment = (data: OpenOrderModel) => {
      try {
        if (
          selectedPaymentOption &&
          selectedPaymentOption.value &&
          safeCharge
        ) {
          safeCharge.createPayment(
            {
              sessionToken: data.sessionToken,
              userTokenId: data.userTokenId,
              clientUniqueId: data.clientUniqueId,
              clientRequestId: data.clientRequestId,
              amount: data.amount,
              currency: 'USD',
              paymentOption: {
                userPaymentOptionId: selectedPaymentOption.value,
                card: {
                  CVV: cvv,
                },
              },
            },
            (resp: any) => {
              dispatch(
                transactionCallback({
                  type: TransactionType.ADD_MONEY_TO_WALLET,
                  amount: parseInt(data.amount, 10),
                  payload: JSON.stringify({
                    paymentResponse: resp,
                  }),
                }),
              );
            },
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if (openPaymentOrderSuccess) {
      if (openOrder) {
        dispatch(resetOpenPaymentOrder());
        createPayment(openOrder);
      }
    }
  }, [
    openPaymentOrderSuccess,
    openOrder,
    cvv,
    dispatch,
    safeCharge,
    selectedPaymentOption,
  ]);

  useEffect(() => {
    if (transactionCallbackSuccess && transaction) {
      dispatch(addMoneyToWallet({ transactionId: transaction.id }));
      dispatch(resetTransactionCallbackSuccess());
    }
  }, [dispatch, transactionCallbackSuccess, transaction]);

  useEffect(() => {
    if (addMoneyToWalletSuccess) {
      dispatch(resetAddMoneyToWalletSuccess());
      dispatch(whoami());
      onMoneyAdd();
    }
  }, [dispatch, addMoneyToWalletSuccess, transaction, onMoneyAdd]);

  const pay = (values: any) => {
    setCvv(values.cvv);
    dispatch(openPaymentOrder({ amount: parseInt(values.amount, 10) }));
    setLoading(true);
  };

  const validatePayment = (values: any) => {
    const errors: any = {};

    if (!values.cvv) {
      errors.cvv = accountTxt.enterCVVErrorText;
    }

    if (!values.amount) {
      errors.cvv = accountTxt.amountErrorText;
    }

    if (!selectedPaymentOption) {
      errors.cvv = accountTxt.selectCardErrorText;
    }

    return errors;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onPaymentOptionChange(val: any) {
    setSelectedPaymentOption(val);
  }

  return (
    <>
      <Formik
        initialValues={{
          paymentOptionId: '',
          cvv: '',
          amount: '',
        }}
        validate={validatePayment}
        onSubmit={pay}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="deposit-money-form">
              <div className="form-group mb-4">
                <label htmlFor="youSend" className="text-muted font-weight-500">
                  {accountTxt.amountLabel}
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text text-5">
                      {appTxt.currencySymbol}
                    </span>
                  </div>
                  <Field name="amount" type="text" className="form-control" />
                </div>
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="countryId"
                  className="text-muted font-weight-500"
                >
                  {accountTxt.selectCardLabel}
                </label>
                <Select
                  value={selectedPaymentOption}
                  options={cards}
                  onChange={onPaymentOptionChange}
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="countryId"
                  className="text-muted font-weight-500"
                >
                  {accountTxt.CVVLabel}
                </label>
                <Field name="cvv" type="text" className="form-control" />
              </div>

              {errors.cvv && touched.cvv && (
                <div className="invalid-feedback-msg">{errors.cvv}</div>
              )}

              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? appTxt.loading : accountTxt.addBtnText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DepositMoneyForm;
