import React from 'react';

import ChangePasswordPage from './change-password/ChangePasswordPage';
import PersonalDetailsPage from './personal-details/PersonalDetailsPage';
import SidePanelPage from './side-panel/SidePanelPage';
import TwoFactorAuthPage from './two-factor-auth/TwoFactorAuthPage';

const ProfilePage: React.FC = (): JSX.Element => {
  return (
    <>
      <div id="main-wrapper" className="bg-primary gradient-color userpage">
        <div id="content" className="py-4 main-user-section">
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
            alt=""
            className="shape-1"
          />
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
            alt=""
            className="shape-2"
          />
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <SidePanelPage />
              </aside>
              <div className="col-lg-9">
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <ChangePasswordPage />
                </div>
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <PersonalDetailsPage />
                </div>
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <TwoFactorAuthPage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
