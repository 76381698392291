/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';

import LanguageTexts from '../../../common/language';
import { ChangePasswordInput } from '../../../common/types';

type ChangePasswordFormProps = {
  onSubmit: (data: ChangePasswordInput) => void;
  errors: string[];
  loading: boolean;
  initialValues: ChangePasswordInput;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  onSubmit,
  errors,
  loading,
  initialValues,
}: ChangePasswordFormProps): JSX.Element => {
  const { app: appTxt, profile: profileTxt } = LanguageTexts;
  const { ...initVals } = initialValues;

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.password) {
      errors.password = profileTxt.passwordErrorText;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initVals}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isValidating }) => (
          <Form className="my-login-validation">
            <div className="form-group mb-4">
              <label htmlFor="password" className="text-muted font-weight-500">
                {profileTxt.newPasswordLabel}
              </label>
              <Field name="password" type="password" className="form-control" />
              {errors.password && touched.password && (
                <div className="invalid-feedback-msg">{errors.password}</div>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? appTxt.loading : profileTxt.changeBtnText}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePasswordForm;
