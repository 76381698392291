/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PublicRoutePath } from '../../common/constants';
import { RegisterInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  loadCountries,
  loadCountriesError,
  loadCountriesSuccess,
  loadStates,
  loadStatesError,
  loadStatesSuccess,
  register,
  registerError,
  registerSuccess,
} from './register.slice';

export function* registerAsync(action: PayloadAction<RegisterInput>) {
  const { data, errors } = yield call(ApiService.register, action.payload);

  if (errors) {
    yield put(registerError(errors));
  } else {
    yield put(registerSuccess(data));
    yield put(push(PublicRoutePath.RegisterSuccess));
    yield window.scrollTo(0, 0);
  }
}

export function* loadCountryAsync() {
  const { data } = yield call(ApiService.getCountries);

  if (!data) {
    yield put(loadCountriesError(['404']));
  } else {
    yield put(loadCountriesSuccess(data));
  }
}

export function* loadStateAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.getStates, action.payload);

  if (!data) {
    yield put(loadStatesError(['404']));
  } else {
    yield put(loadStatesSuccess(data));
  }
}

export function* watchRegisterAsync() {
  yield takeLatest(register.toString(), registerAsync);
  yield takeLatest(loadCountries.toString(), loadCountryAsync);
  yield takeLatest(loadStates.toString(), loadStateAsync);
}
