import { Field, Form, Formik } from 'formik';
import * as maskEmailsPhones from 'mask-email-phone';
import React from 'react';

import LanguageTexts from '../../common/language';

type LoginVerifyTypeFormProps = {
  loading: boolean;
  email: string;
  mobile: string;
  onSubmit: (type: string) => void;
};

const LoginVerifyTypeForm: React.FC<LoginVerifyTypeFormProps> = ({
  loading,
  email,
  mobile,
  onSubmit,
}: LoginVerifyTypeFormProps): JSX.Element => {
  const { login: loginTxt } = LanguageTexts;
  const initialValues = {
    type: '',
  };
  const validate = (values: any) => {
    const errors: any = {};

    if (!values.type) {
      errors.type = 'Please select where to send OTP.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values.type);
        setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="my-login-validation">
          <div className="custom-control custom-radio">
            <Field
              type="radio"
              id="email-verify"
              name="type"
              className="custom-control-input"
              value="email"
            />
            <label
              className="custom-control-label text-muted font-weight-500"
              htmlFor="email-verify"
            >
              <span className="fas fa-envelope mr-2" />
              {loginTxt.emailVerifyLabel}
              <br />
              <span className="text-dark ml-4">{maskEmailsPhones(email)}</span>
            </label>
          </div>
          <div className="my-4" />
          <div className="custom-control custom-radio">
            <Field
              type="radio"
              id="sms-verify"
              name="type"
              className="custom-control-input"
              value="mobile"
            />
            <label
              className="custom-control-label text-muted font-weight-500"
              htmlFor="sms-verify"
            >
              <span className="fas fa-mobile-alt mr-2" />
              {loginTxt.smsVerifyLabel}
              <br />
              <span className="text-dark ml-4">{maskEmailsPhones(mobile)}</span>
            </label>
          </div>

          {errors.type && touched.type && (
            <div className="invalid-feedback-msg">{errors.type}</div>
          )}
          <div className="form-group mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loginTxt.verifyContinue}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginVerifyTypeForm;
