import { Field, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import Select from 'react-select';

import LanguageTexts from '../../../../common/language';
import { AddCardInput } from '../../../../common/types';

type CreditCardFormProps = {
  onSubmit: (data: AddCardInput) => void;
  onCancel: () => void;
  errors: string[];
  loading: boolean;
};

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  onSubmit,
  onCancel,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errors,
  loading,
}: CreditCardFormProps): JSX.Element => {
  const { app: appTxt, account: accountTxt } = LanguageTexts;
  const initialValues: AddCardInput = {
    ccNameOnCard: '',
    ccCardNumber: '',
    ccExpMonth: '',
    ccExpYear: '',
  };

  // local state
  const [selectedExpMonth, setSelectedExpMonth] = useState<any>();
  const [selectedExpYear, setSelectedExpYear] = useState<any>();

  // expiry date dropdown values
  const expMonths = useMemo(() => {
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ].map((val) => {
      return {
        value: val,
        label: val,
      };
    });

    return months;
  }, []);
  const curYear = new Date().getFullYear();
  const expYears = useMemo(() => {
    const years = [];

    for (let i = 0; i <= 10; i += 1) {
      const year = curYear + i;
      const yearStr = year.toString().substr(-2);
      years.push({
        value: yearStr,
        label: year,
      });
    }

    return years;
  }, [curYear]);

  const validate = (values: any) => {
    const errorsIn: any = {};

    if (!values.ccNameOnCard) {
      errorsIn.ccNameOnCard = accountTxt.cardHolderNameErrorText;
    }

    if (!values.ccCardNumber) {
      errorsIn.ccCardNumber = accountTxt.cardNumberErrorText;
    }

    if (!values.ccExpMonth) {
      errorsIn.ccExpMonth = accountTxt.expirationMonthErrorText;
    }

    if (!values.ccExpYear) {
      errorsIn.ccExpYear = accountTxt.expirationYearErrorText;
    }

    return errorsIn;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit({ ...values });
          setSubmitting(false);
        }}
      >
        {({ errors: errorsIn, touched, setFieldValue }) => {
          return (
            <Form>
              <div className="form-group mb-4">
                <label
                  htmlFor="ccNameOnCard"
                  className="text-muted font-weight-500"
                >
                  {accountTxt.cardHolderNameLabel}
                </label>
                <Field
                  name="ccNameOnCard"
                  type="text"
                  className="form-control"
                />
                {errorsIn.ccNameOnCard && touched.ccNameOnCard && (
                  <div className="invalid-feedback-msg">
                    {errorsIn.ccNameOnCard}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="ccCardNumber"
                  className="text-muted font-weight-500"
                >
                  {accountTxt.cardNumberLabel}
                </label>
                <Field
                  name="ccCardNumber"
                  type="text"
                  className="form-control"
                />
                {errorsIn.ccCardNumber && touched.ccCardNumber && (
                  <div className="invalid-feedback-msg">
                    {errorsIn.ccCardNumber}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="ccExpMonth"
                  className="text-muted font-weight-500"
                >
                  {accountTxt.expirationMonthLabel}
                </label>
                <Select
                  placeholder="Select Month"
                  value={selectedExpMonth}
                  options={expMonths}
                  onChange={(val) => {
                    setSelectedExpMonth(val);
                    setFieldValue('ccExpMonth', val.value);
                  }}
                />
                {errorsIn.ccExpMonth && touched.ccExpMonth && (
                  <div className="invalid-feedback-msg">
                    {errorsIn.ccExpMonth}
                  </div>
                )}
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="ccExpYear"
                  className="text-muted font-weight-500"
                >
                  {accountTxt.expirationYearLabel}
                </label>
                <Select
                  placeholder="Select Year"
                  value={selectedExpYear}
                  options={expYears}
                  onChange={(val) => {
                    setSelectedExpYear(val);
                    setFieldValue('ccExpYear', val.value);
                  }}
                />
                {errorsIn.ccExpYear && touched.ccExpYear && (
                  <div className="invalid-feedback-msg">
                    {errorsIn.ccExpYear}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? appTxt.loading : accountTxt.addCardText}
              </button>
              <button
                type="button"
                className="btn btn-primary ml-2"
                disabled={loading}
                onClick={() => onCancel()}
              >
                {accountTxt.cancelBtnText}
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreditCardForm;
