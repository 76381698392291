/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BetInput,
  GameModel,
  GameState,
  JoinPoolInput,
  SelectedBet,
  TransactionInput,
  TransactionModel,
  UserPoolOngoingModel,
} from '../../common/types';

const initialState: GameState = {
  loading: false,
  success: null,
  errors: [],
  games: null,
  userBetId: null,
  bet: null,
  transaction: null,
  checkUserTransaction: null,
  myOngoingPoolModel: null,
  allUserOngoingPools: null,
  userCountInPool: null,
  betState: {
    loading: false,
    success: null,
    errors: [],
  },
  selectedBetState: {
    loading: false,
    success: null,
    errors: [],
  },
  checkTransactionState: {
    loading: false,
    success: null,
    errors: [],
  },
  transactionCallbackState: {
    loading: false,
    success: null,
    errors: [],
  },
  myOngoingPoolState: {
    loading: false,
    success: null,
    errors: [],
  },
  allUserOngoingPoolsState: {
    loading: false,
    success: null,
    errors: [],
  },
  getUserCountInPoolState: {
    loading: false,
    success: null,
    errors: [],
  },
  joinPoolState: {
    loading: false,
    success: null,
    errors: [],
  },
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadGames(state, action: PayloadAction<any>) {
      state.loading = true;
      state.success = null;
      state.games = null;
      state.errors = [];
    },
    loadGamesSuccess(state, action: PayloadAction<GameModel[]>) {
      state.loading = false;
      state.games = action.payload;
      state.success = !!action.payload;
    },
    loadGamesError(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveUserBet(state, action: PayloadAction<BetInput>) {
      state.betState.loading = true;
      state.betState.errors = [];
    },
    saveUserBetSuccess(state, action: PayloadAction<string>) {
      state.betState.loading = false;
      state.betState.success = !!action.payload;
      state.userBetId = action.payload;
    },
    saveUserBetError(state, action: PayloadAction<string[]>) {
      state.betState.loading = false;
      state.betState.errors = action.payload;
    },
    resetSaveUserBetSuccess(state) {
      state.betState.success = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transactionCallback(state, action: PayloadAction<TransactionInput>) {
      state.transactionCallbackState.loading = true;
      state.transactionCallbackState.errors = [];
    },
    transactionCallbackSuccess(state, action: PayloadAction<TransactionModel>) {
      state.transactionCallbackState.loading = false;
      state.transactionCallbackState.success = !!action.payload;
      state.transaction = action.payload;
    },
    transactionCallbackError(state, action: PayloadAction<string[]>) {
      state.transactionCallbackState.loading = false;
      state.transactionCallbackState.errors = action.payload;
    },
    resetTransactionCallbackSuccess(state) {
      state.transactionCallbackState.success = null;
      state.transaction = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadBet(state, action: PayloadAction<string>) {
      state.selectedBetState.loading = true;
      state.selectedBetState.errors = [];
    },
    loadBetSuccess(state, action: PayloadAction<SelectedBet[]>) {
      state.selectedBetState.loading = false;
      state.bet = action.payload;
      state.selectedBetState.success = !!action.payload;
    },
    loadBetError(state, action: PayloadAction<string[]>) {
      state.selectedBetState.loading = false;
      state.selectedBetState.errors = action.payload;
    },
    resetLoadBet(state) {
      state.selectedBetState.success = null;
      state.selectedBetState.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkTransaction(state, action: PayloadAction<string>) {
      state.checkTransactionState.loading = true;
      state.checkTransactionState.errors = [];
    },
    checkTransactionSuccess(state, action: PayloadAction<boolean>) {
      state.checkTransactionState.loading = false;
      state.checkUserTransaction = action.payload;
      state.checkTransactionState.success = !!action.payload;
    },
    checkTransactionError(state, action: PayloadAction<string[]>) {
      state.checkTransactionState.loading = false;
      state.checkTransactionState.errors = action.payload;
    },
    resetCheckTransaction(state) {
      state.checkTransactionState.success = null;
      state.checkTransactionState.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    myOngoingPool(state, action: PayloadAction<string>) {
      state.myOngoingPoolState.loading = true;
      state.myOngoingPoolState.errors = [];
    },
    myOngoingPoolSuccess(
      state,
      action: PayloadAction<UserPoolOngoingModel | null>,
    ) {
      state.myOngoingPoolState.loading = false;
      state.myOngoingPoolModel = action.payload;
      state.myOngoingPoolState.success = true;
    },
    myOngoingPoolError(state, action: PayloadAction<string[]>) {
      state.myOngoingPoolState.loading = false;
      state.myOngoingPoolState.errors = action.payload;
    },
    resetMyOngoingPool(state) {
      state.myOngoingPoolState.success = null;
      state.myOngoingPoolState.errors = [];
    },
    allUserOngoingPools(state) {
      state.allUserOngoingPoolsState.loading = true;
      state.allUserOngoingPoolsState.errors = [];
    },
    allUserOngoingPoolsSuccess(
      state,
      action: PayloadAction<[UserPoolOngoingModel] | null>,
    ) {
      state.allUserOngoingPoolsState.loading = false;
      state.allUserOngoingPools = action.payload;
      state.allUserOngoingPoolsState.success = true;
    },
    allUserOngoingPoolsError(state, action: PayloadAction<string[]>) {
      state.allUserOngoingPoolsState.loading = false;
      state.allUserOngoingPoolsState.errors = action.payload;
    },
    resetAllUserOngoingPools(state) {
      state.allUserOngoingPoolsState.success = null;
      state.allUserOngoingPoolsState.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserCountInPool(state, action: PayloadAction<string>) {
      state.getUserCountInPoolState.loading = true;
      state.getUserCountInPoolState.errors = [];
    },
    getUserCountInPoolSuccess(state, action: PayloadAction<number | null>) {
      state.getUserCountInPoolState.loading = false;
      state.userCountInPool = action.payload;
      state.getUserCountInPoolState.success = true;
    },
    getUserCountInPoolError(state, action: PayloadAction<string[]>) {
      state.getUserCountInPoolState.loading = false;
      state.getUserCountInPoolState.errors = action.payload;
    },
    resetGetUserCountInPool(state) {
      state.getUserCountInPoolState.success = null;
      state.getUserCountInPoolState.errors = [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    joinPool(state, action: PayloadAction<JoinPoolInput>) {
      state.joinPoolState.loading = true;
      state.joinPoolState.errors = [];
    },
    joinPoolSuccess(state, action: PayloadAction<UserPoolOngoingModel>) {
      state.joinPoolState.loading = false;
      state.joinPoolState.success = !!action.payload;
      state.myOngoingPoolModel = action.payload;
    },
    joinPoolError(state, action: PayloadAction<string[]>) {
      state.joinPoolState.loading = false;
      state.joinPoolState.errors = action.payload;
    },
    resetJoinPoolSuccess(state) {
      state.joinPoolState.success = null;
    },
  },
});

export const {
  loadGames,
  loadGamesSuccess,
  loadGamesError,
  saveUserBet,
  saveUserBetSuccess,
  saveUserBetError,
  resetSaveUserBetSuccess,
  loadBet,
  loadBetSuccess,
  loadBetError,
  resetLoadBet,
  checkTransaction,
  checkTransactionSuccess,
  checkTransactionError,
  resetCheckTransaction,
  transactionCallback,
  transactionCallbackSuccess,
  transactionCallbackError,
  resetTransactionCallbackSuccess,
  myOngoingPool,
  myOngoingPoolSuccess,
  myOngoingPoolError,
  resetMyOngoingPool,
  allUserOngoingPools,
  allUserOngoingPoolsSuccess,
  allUserOngoingPoolsError,
  resetAllUserOngoingPools,
  getUserCountInPool,
  getUserCountInPoolSuccess,
  getUserCountInPoolError,
  resetGetUserCountInPool,
  joinPool,
  joinPoolSuccess,
  joinPoolError,
  resetJoinPoolSuccess,
} = gameSlice.actions;

export const { name: gameSliceName, reducer: gameSliceReducer } = gameSlice;
