import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutePath } from '../../common/constants';
import LanguageTexts from '../../common/language';
import { RootState } from '../../common/types';
import { resetSendLoginOTPSuccess, sendLoginOTP } from './login.slice';
import LoginVerifyTypeForm from './LoginVerifyTypeForm';

const LoginVerifyPage: React.FC = (): JSX.Element => {
  const { login: loginTxt } = LanguageTexts;
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    sendLoginOTP: {
      loading: sendLoginOTPLoading,
      success: sendLoginOTPSuccess,
      errors: sendLoginOTPErrors,
    },
  } = useSelector((state: RootState) => state.login);

  const userData: any = history.location.state;

  useEffect(() => {
    if (sendLoginOTPSuccess) {
      dispatch(resetSendLoginOTPSuccess());
      toast.success('OTP Sent Successfully.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push(AppRoutePath.LoginVerifyOtp, userData);
    } else if (sendLoginOTPErrors && sendLoginOTPErrors.length > 0) {
      toast.error(sendLoginOTPErrors[0], {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [sendLoginOTPSuccess, sendLoginOTPErrors, dispatch, history, userData]);

  function onSubmit(type: string) {
    dispatch(
      sendLoginOTP({ input: { type }, access_token: userData.access_token }),
    );
  }

  if (!userData) {
    return <p>Something went wrong.</p>;
  }

  return (
    <>
      <div
        className="main-container login-page"
        style={{
          background: `linear-gradient(0deg, #4d96f6f0 50%, #1a4eae8a 100%), url(${process.env.REACT_APP_IMAGE_PATH}website/bg-login.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="card-wrapper">
                <div className="card fat">
                  <div className="card-body">
                    <h3 className="text-10 font-weight-500 mb-5">
                      {loginTxt.verifyHeading}
                    </h3>
                    <LoginVerifyTypeForm
                      email={userData.email}
                      mobile={userData.twoFA.mobile}
                      onSubmit={onSubmit}
                      loading={sendLoginOTPLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default LoginVerifyPage;
