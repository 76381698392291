import _ from 'lodash';
import { useMemo } from 'react';

export default function useUserOngoingPools(records: any[]): any {
  const result: any = useMemo(() => {
    const userOnPools: any = [];

    _.forEach(_.groupBy(records, 'poolId'), (value) => {
      let userOnPoolsByCoins: any = [];

      _.forEach(_.groupBy(value, 'coins'), (valueIn, keyIn) => {
        userOnPoolsByCoins.push({
          coins: +keyIn,
          users: valueIn,
        });
      });

      userOnPoolsByCoins = _.sortBy(userOnPoolsByCoins, 'coins').reverse();

      userOnPools.push({
        poolSlug: value[0].pool.value,
        pool: value[0].pool,
        usersByRank: userOnPoolsByCoins,
      });
    });
    return _.keyBy(userOnPools, 'poolSlug');
  }, [records]);

  return result;
}
