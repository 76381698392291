import { ValidationError } from 'class-validator';
import { GraphQLError } from 'graphql';
import { flattenDeep } from 'lodash';
import moment from 'moment-timezone';

import LanguageTexts from './language';

declare const window: any;

// eslint-disable-next-line import/prefer-default-export
export function extractErrors(err: GraphQLError[]): string[] {
  const [{ extensions, message }] = err;
  let errors = [];

  if (extensions) {
    if (extensions.exception.response) {
      const { message: resMessage } = extensions.exception.response;

      if (Array.isArray(resMessage)) {
        errors = resMessage;
      } else {
        errors = [resMessage];
      }
    } else {
      errors = [message];
    }
  }

  return errors;
}

export function formatApiResponse(
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any> | null,
  errors: GraphQLError[] | null,
): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  errors: string[] | null;
} {
  return {
    data: data ? data[type] : null,
    errors: errors ? extractErrors(errors) : null,
  };
}

export function formatApiException(exception: {
  message: string;
}): {
  data: null;
  errors: string[];
} {
  return { data: null, errors: [exception.message] };
}

export function hasError(
  key: string,
  errors: string[],
): string | null | undefined {
  return errors ? errors.find((err) => err.includes(key)) : null;
}

export function formatErrors(
  errorKeys: { langKey: string; errKey: string; errPrefix?: string }[],
  errors: string[],
  langTxt: Record<string, string>,
): string[] {
  return errors
    ? errors.map((err) => {
        for (let i = 0; i < errorKeys.length; i += 1) {
          const { errKey, langKey, errPrefix } = errorKeys[i];

          if (err.includes(errKey)) {
            return err
              .replace(
                errKey,
                `${langTxt[langKey]} ${errPrefix ? +errPrefix + 1 : ''}`,
              )
              .replace('input.each', LanguageTexts.app.each)
              .replace('input.', '');
          }
        }
        return err.replace('input.', '');
      })
    : [];
}

function getKeysForArray(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: Record<string, any>[],
  parentKeyName: string,
  topParentKeyName: string,
): { langKey: string; errKey: string; errPrefix?: string }[] {
  let result: { langKey: string; errKey: string; errPrefix?: string }[] = [];

  input.forEach((obj, index) => {
    if (typeof obj === 'string') {
      result.push({
        langKey: `${topParentKeyName}.${parentKeyName}`,
        errKey: `${parentKeyName}`,
      });

      return false;
    }

    const objKeys = Object.keys(obj);

    objKeys.forEach((key) => {
      if (obj[key] && Array.isArray(obj[key])) {
        let arrayKeys = getKeysForArray(obj[key], key, parentKeyName);

        if (typeof obj[key][0] === 'string') {
          arrayKeys = arrayKeys.map((arObj) => {
            return {
              ...arObj,
              errPrefix: `${index}.`,
              errKey: `${index}.${key}`,
            };
          });
        }

        result = [...result, ...arrayKeys];
      } else if (obj[key] && typeof obj[key] === 'object') {
        result = [
          ...result,
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          ...getKeysForObject(obj[key], key),
        ];
      } else {
        result.push({
          langKey: `${parentKeyName}.${key}`,
          errKey: `${index}.${key}`,
          errPrefix: `${index}.`,
        });
      }
    });

    return true;
  });

  return result;
}

function getKeysForObject(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: Record<string, any>,
  parentKeyName: string,
): { langKey: string; errKey: string; errPrefix?: string }[] {
  let result: { langKey: string; errKey: string; errPrefix?: string }[] = [];

  const objKeys = Object.keys(input);

  objKeys.forEach((key) => {
    if (input[key] && Array.isArray(input[key])) {
      result = [...result, ...getKeysForArray(input[key], key, parentKeyName)];
    } else if (input[key] && typeof input[key] === 'object') {
      result = [...result, ...getKeysForObject(input[key], key)];
    } else {
      result.push({
        langKey: `${parentKeyName}.${key}`,
        errKey: `${parentKeyName}.${key}`,
      });
    }
  });

  return result;
}

export function getLangAndErrKeys(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any[] | Record<string, any>,
): { langKey: string; errKey: string; errPrefix?: string }[] {
  let result: { langKey: string; errKey: string; errPrefix?: string }[] = [];

  if (Array.isArray(input)) {
    result = [...result, ...getKeysForArray(input, 'input', '')];
  } else {
    result = [...result, ...getKeysForObject(input, 'input')];
  }

  return result;
}

export function extractClassValidatorErr(errs: ValidationError[]): string[] {
  return flattenDeep(
    errs.map(({ constraints, property }) =>
      Object.values(constraints || {}).map((val) =>
        val.replace(property, `input.${property}`),
      ),
    ),
  );
}

export function getWeekDaysBetweenDates(
  startDate: moment.Moment,
  endDate: moment.Moment,
): number[] {
  const dayDiff = Math.ceil(endDate.diff(startDate, 'days', true)) + 1;

  const allowedDays = [];

  if (dayDiff > 0 && dayDiff < 7) {
    for (let i = 0; i < dayDiff; i += 1) {
      allowedDays.push(startDate.clone().add(i, 'd').day());
    }
  }

  return allowedDays;
}

export function isWeekDayExistBetweenDates(
  day: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
): boolean {
  const weekDays = getWeekDaysBetweenDates(startDate, endDate);

  return weekDays.length > 0
    ? weekDays.find((wDay) => wDay === day) !== undefined
    : true;
}

export function isPastDate(date: moment.Moment, timezone: string): boolean {
  return !!(
    date &&
    date
      .clone()
      .set({ h: 0, m: 0, s: 0 })
      .isBefore(moment.tz(undefined, timezone))
  );
}

export function isDayAllowed(
  day: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  timezone: string,
): boolean {
  const isStartDateGone = isPastDate(startDate, timezone);
  const isEndDateGone = isPastDate(endDate, timezone);

  if (isStartDateGone && isEndDateGone) {
    return false;
  }

  return isWeekDayExistBetweenDates(
    +day,
    isStartDateGone
      ? moment.tz(undefined, timezone).set({ h: 12, m: 0, s: 0 }).add('1', 'd')
      : startDate,
    endDate,
  );
}

export function setTimeToStart(date: string): string {
  return `${date}T00:00:00.001`;
}

export function setTimeToEnd(date: string): string {
  return `${date}T23:59:59.999`;
}

export function calculateProcessingFees(
  amount: number,
  feesPercentage: number,
  feesAmountInCent: number,
): number {
  const amountWithFees =
    (amount + feesAmountInCent / 100) / (1 - feesPercentage / 100);

  const fees = Math.round((amountWithFees - amount) * 100) / 100;

  return Math.floor(fees * 100);
}

export function getUrlParameterByName(
  paramName: string,
  url: string,
): string | null {
  const name = paramName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function loadScript(src: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement('script'), {
      type: 'text/javascript',
      defer: true,
      src,
      onerror: (e: any) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve(null);
    };
    document.body.appendChild(scriptElem);
  });
}

export function loadSafeCharge(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (window.safeChargeObj) {
      resolve(window.safeChargeObj);
    } else {
      loadScript(
        'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js',
      )
        .then(() => {
          window.safeChargeObj = window.SafeCharge({
            env: process.env.REACT_APP_PAYMENT_MODE,
            merchantId: process.env.REACT_APP_MERCHANT_ID,
            merchantSiteId: process.env.REACT_APP_MERCHANT_SITE_ID,
          });
          resolve(window.safeChargeObj);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
}
