/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { BetInput, JoinPoolInput, TransactionInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  allUserOngoingPools,
  allUserOngoingPoolsError,
  allUserOngoingPoolsSuccess,
  checkTransaction,
  checkTransactionError,
  checkTransactionSuccess,
  getUserCountInPool,
  getUserCountInPoolError,
  getUserCountInPoolSuccess,
  joinPool,
  joinPoolError,
  joinPoolSuccess,
  loadBet,
  loadBetError,
  loadBetSuccess,
  loadGames,
  loadGamesError,
  loadGamesSuccess,
  myOngoingPool,
  myOngoingPoolError,
  myOngoingPoolSuccess,
  saveUserBet,
  saveUserBetError,
  saveUserBetSuccess,
  transactionCallback,
  transactionCallbackError,
  transactionCallbackSuccess,
} from './games.slice';

export function* loadGameAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.getGames, action.payload);

  if (!data) {
    yield put(loadGamesError(['404']));
  } else {
    yield put(loadGamesSuccess(data));
  }
}

export function* saveBetAsync(action: PayloadAction<BetInput>) {
  const { data } = yield call(ApiService.saveBet, action.payload);

  if (!data) {
    yield put(saveUserBetError(['404']));
  } else {
    yield put(saveUserBetSuccess(data));
  }
}

export function* loadBetAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.loadUserBet, action.payload);

  if (!data) {
    yield put(loadBetError(['404']));
  } else {
    yield put(loadBetSuccess(data));
  }
}

export function* checkTransactionAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.checkTransaction,
    action.payload,
  );

  if (errors) {
    yield put(checkTransactionError(errors));
  } else {
    yield put(checkTransactionSuccess(data));
  }
}

export function* transactionCallbackAsync(
  action: PayloadAction<TransactionInput>,
) {
  const { data } = yield call(ApiService.transactionCallback, action.payload);

  if (!data) {
    yield put(transactionCallbackError(['404']));
  } else {
    yield put(transactionCallbackSuccess(data));
  }
}

export function* myOngoingPoolAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(ApiService.myOngoingPool, action.payload);

  if (errors) {
    yield put(myOngoingPoolError(errors));
  } else {
    yield put(myOngoingPoolSuccess(data));
  }
}

export function* allUserOngoingPoolsAsync() {
  const { data, errors } = yield call(ApiService.allUserOngoingPools);

  if (errors) {
    yield put(allUserOngoingPoolsError(errors));
  } else {
    yield put(allUserOngoingPoolsSuccess(data));
  }
}

export function* getUserCountInPoolAsync(action: PayloadAction<string>) {
  const { data, errors } = yield call(
    ApiService.getUserCountInPool,
    action.payload,
  );

  if (errors) {
    yield put(getUserCountInPoolError(errors));
  } else {
    yield put(getUserCountInPoolSuccess(data));
  }
}

export function* joinPoolAsync(action: PayloadAction<JoinPoolInput>) {
  const { data } = yield call(ApiService.joinPool, action.payload);

  if (!data) {
    yield put(joinPoolError(['404']));
  } else {
    yield put(joinPoolSuccess(data));
  }
}

export function* watchGameAsync() {
  yield takeLatest(loadGames.toString(), loadGameAsync);
  yield takeLatest(saveUserBet.toString(), saveBetAsync);
  yield takeLatest(loadBet.toString(), loadBetAsync);
  yield takeLatest(checkTransaction.toString(), checkTransactionAsync);
  yield takeLatest(transactionCallback.toString(), transactionCallbackAsync);
  yield takeLatest(myOngoingPool.toString(), myOngoingPoolAsync);
  yield takeLatest(allUserOngoingPools.toString(), allUserOngoingPoolsAsync);
  yield takeLatest(getUserCountInPool.toString(), getUserCountInPoolAsync);
  yield takeLatest(joinPool.toString(), joinPoolAsync);
}
