import React from 'react';

export default function PoolUsersList({
  users,
}: {
  users: any[];
}): JSX.Element {
  return (
    <>
      {users.map((apUser: any, rank: any) => {
        return apUser.users.map((pUser: any) => {
          return (
            <div
              key={`user-pool-${pUser.poolId}-${pUser.user._id}`}
              className="list-group-item d-flex media align-items-center bg-black"
            >
              <a href="#" className="avatar avatar-sm media-left mr-3">
                <img
                  src={
                    pUser.user.profilePicUrl !== null
                      ? pUser.user.profilePicUrl
                      : `${process.env.REACT_APP_IMAGE_PATH}website/user.png`
                  }
                  alt="Avatar"
                  className="avatar-img rounded-circle"
                />
              </a>
              <div className="media-body">
                <p className="m-0 float-left text-left">
                  <a href="#">
                    <strong className="text-white">
                      {pUser.user.firstName}&nbsp;
                      {pUser.user.lastName}
                    </strong>
                  </a>
                  <br />
                  <span className="text-white">Rank {rank + 1}</span>
                </p>
              </div>
              <div className="float-right">
                <p>{Number(apUser.coins.toFixed(0)).toLocaleString().split(/\s/).join(',')} Pts</p>
              </div>
            </div>
          );
        });
      })}
    </>
  );
}
