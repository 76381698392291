import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import LanguageTexts from '../../../common/language';
import { RootState, UpdateProfileInput } from '../../../common/types';

type PersonalDetailsFormProps = {
  onSubmit: (data: UpdateProfileInput) => void;
  onCancel: () => void;
  loading: boolean;
  countries: { name: string; _id: string }[];
};

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({
  onSubmit,
  onCancel,
  loading,
  countries,
}: PersonalDetailsFormProps): JSX.Element => {
  const { app: appTxt, profile: profileTxt, register: registerTxt } = LanguageTexts;

  const { user } = useSelector((state: RootState) => state.login);
  const { address } = useSelector((state: RootState) => state.profile);

  const initialValues: UpdateProfileInput = {
    firstName: `${user?.firstName}`,
    lastName: `${user?.lastName}`,
    streetAddress: `${address?.streetAddress}`,
    city: `${address?.city}`,
    countryId: `${address?.countryId}`,
    state: `${address?.state}`,
    zip: `${address?.zip}`,
  };

  const countryOptions = countries.map(({ _id, name }) => {
    return { value: _id, label: name };
  });

  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.find(({ value }) => value === initialValues.countryId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCountryChange(val: any) {
    setSelectedCountry(val);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            ...values,
            countryId: selectedCountry?.value || '',
          });
          setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form className="my-login-validation">
            <div className="form-group mb-4">
              <label htmlFor="firstName" className="text-muted font-weight-500">
                {registerTxt.firstNameLabel}
              </label>
              <Field name="firstName" type="text" className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="lastName" className="text-muted font-weight-500">
                {registerTxt.lastNameLabel}
              </label>
              <Field name="lastName" type="text" className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="streetAddress" className="text-muted font-weight-500">
                {registerTxt.addressLabel}
              </label>
              <Field name="streetAddress" type="text" className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="zip" className="text-muted font-weight-500">
                {registerTxt.zipLabel}
              </label>
              <Field name="zip" type="text" className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="city" className="text-muted font-weight-500">
                {registerTxt.cityLabel}
              </label>
              <Field name="city" type="text" className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="state" className="text-muted font-weight-500">
                {registerTxt.stateLabel}
              </label>
              <Field name="state" type="text" className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="countryId" className="text-muted font-weight-500">
                {registerTxt.countryLabel}
              </label>
              <Select
                value={selectedCountry}
                options={countryOptions}
                onChange={onCountryChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? appTxt.loading : profileTxt.updateProfileBtnText}
            </button>
            <button
              type="button"
              className="btn btn-primary ml-2"
              disabled={loading}
              onClick={() => onCancel()}
            >
              {profileTxt.cancelBtnText}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalDetailsForm;
