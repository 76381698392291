/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { formatErrors, getLangAndErrKeys } from '../../common/helper';
import LanguageTexts from '../../common/language';
import { RegisterInput } from '../../common/types';

type IRegisterProps = {
  onSubmit: (data: RegisterInput) => void;
  socialLoginData: {
    email: string;
    id: string;
    firstName: string;
    lastName: string;
    type: string;
  };
  errors: string[];
  loading: boolean;
  countries: { name: string; _id: string }[];
};

const RegisterForm: React.FC<IRegisterProps> = ({
  onSubmit,
  errors,
  loading,
  countries,
  socialLoginData,
}: IRegisterProps): JSX.Element => {
  const { register: registerTxt } = LanguageTexts;
  const initialValues: RegisterInput = {
    email: socialLoginData ? socialLoginData.email : '',
    password: '',
    firstName: socialLoginData ? socialLoginData.firstName : '',
    lastName: socialLoginData ? socialLoginData.lastName : '',
    streetAddress: '',
    countryId: '',
    state: '',
    facebookId:
      socialLoginData && socialLoginData.type === 'facebook'
        ? socialLoginData.id
        : '',
    googleId:
      socialLoginData && socialLoginData.type === 'google'
        ? socialLoginData.id
        : '',
    city: '',
    zip: '',
    acceptTerms: false,
    role: 'user',
  };

  const countryOptions = countries.map(({ _id, name }) => {
    return { value: _id, label: name };
  });

  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.find(({ value }) => value === initialValues.countryId),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onCountryChange(val: any) {
    setSelectedCountry(val);
  }

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const errorKeys = getLangAndErrKeys(initialValues);
  const formattedErrors = formatErrors(errorKeys, errors, LanguageTexts.login);

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.firstName) {
      errors.firstName = registerTxt.firstNameErrorText;
    } else if (!/^[a-zA-Z]+$/.test(values.firstName)) {
      errors.firstName = registerTxt.firstNameInvalidErrorText;
    }

    if (!values.lastName) {
      errors.lastName = registerTxt.lastNameErrorText;
    } else if (!/^[a-zA-Z]+$/.test(values.lastName)) {
      errors.lastName = registerTxt.lastNameInvalidErrorText;
    }

    if (!values.email) {
      errors.email = registerTxt.emailErrorText;
    } else if (!validateEmail(values.email)) {
      errors.email = registerTxt.emailInvalidErrorText;
    }

    if (!values.password) {
      errors.password = registerTxt.passwordErrorText;
    }

    if (!values.streetAddress) {
      errors.streetAddress = registerTxt.addressErrorText;
    }

    if (!values.city) {
      errors.city = registerTxt.cityErrorText;
    }

    if (!values.zip) {
      errors.zip = registerTxt.zipErrorText;
    }

    if (!values.state) {
      errors.state = registerTxt.stateErrorText;
    }

    if (!selectedCountry) {
      errors.countryId = registerTxt.countryErrorText;
    }

    if (!values.acceptTerms) {
      errors.acceptTerms = registerTxt.acceptTermsErrorText;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            ...values,
            countryId: selectedCountry?.value || '',
          });
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isValidating, setFieldValue }) => (
          <Form className="my-login-validation">
            <div className="row">
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label
                    htmlFor="firstName"
                    className="text-muted font-weight-500"
                  >
                    {registerTxt.firstNameLabel}
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    className="form-control"
                  />
                  {errors.firstName && touched.firstName && (
                    <div className="invalid-feedback-msg">
                      {errors.firstName}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label
                    htmlFor="lastName"
                    className="text-muted font-weight-500"
                  >
                    {registerTxt.lastNameLabel}
                  </label>
                  <Field name="lastName" type="text" className="form-control" />
                  {errors.lastName && touched.lastName && (
                    <div className="invalid-feedback-msg">
                      {errors.lastName}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label htmlFor="email" className="text-muted font-weight-500">
                    {registerTxt.emailAddressLabel}
                  </label>
                  <Field name="email" type="text" className="form-control" />
                  {errors.email && touched.email && (
                    <div className="invalid-feedback-msg">{errors.email}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label
                    htmlFor="password"
                    className="text-muted font-weight-500"
                  >
                    {registerTxt.passwordLabel}
                  </label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                  />
                  {errors.password && touched.password && (
                    <div className="invalid-feedback-msg">
                      {errors.password}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4">
                <div className="form-group">
                  <label
                    htmlFor="streetAddress"
                    className="text-muted font-weight-500"
                  >
                    {registerTxt.addressLabel}
                  </label>
                  <Field
                    name="streetAddress"
                    type="text"
                    className="form-control"
                  />
                  {errors.streetAddress && touched.streetAddress && (
                    <div className="invalid-feedback-msg">
                      {errors.streetAddress}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label htmlFor="city" className="text-muted font-weight-500">
                    {registerTxt.cityLabel}
                  </label>
                  <Field name="city" type="text" className="form-control" />
                  {errors.city && touched.city && (
                    <div className="invalid-feedback-msg">{errors.city}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label htmlFor="zip" className="text-muted font-weight-500">
                    {registerTxt.zipLabel}
                  </label>
                  <Field name="zip" type="text" className="form-control" />
                  {errors.zip && touched.zip && (
                    <div className="invalid-feedback-msg">{errors.zip}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label htmlFor="state" className="text-muted font-weight-500">
                    {registerTxt.stateLabel}
                  </label>
                  <Field name="state" type="text" className="form-control" />
                  {errors.state && touched.state && (
                    <div className="invalid-feedback-msg">{errors.state}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 mb-4">
                <div className="form-group">
                  <label
                    htmlFor="countryId"
                    className="text-muted font-weight-500"
                  >
                    {registerTxt.countryLabel}
                  </label>
                  <Select
                    value={selectedCountry}
                    options={countryOptions}
                    onChange={onCountryChange}
                  />
                  {errors.countryId && touched.countryId && (
                    <div className="invalid-feedback-msg">
                      {errors.countryId}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group mb-4">
              <div className="custom-checkbox custom-control">
                <Field
                  type="checkbox"
                  name="acceptTerms"
                  id="acceptTerms"
                  className="custom-control-input"
                />
                <label
                  htmlFor="acceptTerms"
                  className="custom-control-label text-muted font-weight-500"
                >
                  {registerTxt.agreeTermsText}{' '}
                  <Link to="#">{registerTxt.termsConditionLinkText}</Link>
                </label>
              </div>
              {errors.acceptTerms && touched.acceptTerms && (
                <div className="invalid-feedback-msg">{errors.acceptTerms}</div>
              )}
            </div>

            {formattedErrors.map((error) => (
              <p
                className="invalid-feedback-msg"
                style={{ fontWeight: 'bold' }}
                key={error}
              >
                {error}
              </p>
            ))}

            <div className="form-group m-0">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {registerTxt.registerText}
              </button>
            </div>

            <hr className="mt-4" />
            <div className="mt-4 text-center text-muted font-weight-500">
              {registerTxt.alreadyAccountText}{' '}
              <Link to="/login"> {registerTxt.loginLinkText}</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegisterForm.defaultProps = {};

export default RegisterForm;
