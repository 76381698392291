/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all, call } from 'redux-saga/effects';

import { watchForgotPasswordAsync } from '../features/forgot-password/forgot-password.saga';
import { watchGameAsync } from '../features/games/games.saga';
import { watchPoolAsync } from '../features/home/home.saga';
import { watchLoginAsync } from '../features/login/login.saga';
import { watchProfileAsync } from '../features/profile/profile.saga';
import { watchRegisterAsync } from '../features/register/register.saga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    call(watchLoginAsync),
    call(watchRegisterAsync),
    call(watchForgotPasswordAsync),
    call(watchGameAsync),
    call(watchPoolAsync),
    call(watchProfileAsync),
  ]);
}
