/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  AddMoneyToWalletInput,
  ChangeDefaultCardInput,
  ChangePasswordInput,
  DeductMoneyFromWalletInput,
  OpenPaymentOrderInput,
  UpdateProfileInput,
  UpdateProfilePicInput,
  VerifyMobileInput,
  VerifyTokenInput,
} from '../../common/types';
import ApiService from '../../services/api';
import {
  addMoneyToWallet,
  addMoneyToWalletError,
  addMoneyToWalletSuccess,
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  checkTokenToVerifyMobile,
  checkTokenToVerifyMobileError,
  checkTokenToVerifyMobileSuccess,
  deductMoneyFromWallet,
  deductMoneyFromWalletError,
  deductMoneyFromWalletSuccess,
  deletePaymentMethod,
  deletePaymentMethodError,
  deletePaymentMethodSuccess,
  disableTwoFA,
  disableTwoFAError,
  disableTwoFASuccess,
  generateWithdrawUrl,
  generateWithdrawUrlError,
  generateWithdrawUrlSuccess,
  getWithdrawalRequests,
  getWithdrawalRequestsError,
  getWithdrawalRequestsSuccess,
  loadCardInfo,
  loadCardInfoError,
  loadCardInfoSuccess,
  loadMyPoolBets,
  loadMyPoolBetsError,
  loadMyPoolBetsSuccess,
  loadUserBets,
  loadUserBetsError,
  loadUserBetsSuccess,
  openPaymentOrder,
  openPaymentOrderError,
  openPaymentOrderSuccess,
  sendTokenToVerifyMobile,
  sendTokenToVerifyMobileError,
  sendTokenToVerifyMobileSuccess,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodError,
  setDefaultPaymentMethodSuccess,
  showAddress,
  showAddressError,
  showAddressSuccess,
  updateProfile,
  updateProfileError,
  updateProfilePic,
  updateProfilePicError,
  updateProfilePicSuccess,
  updateProfileSuccess,
} from './profile.slice';

export function* updateProfilePicAsync(
  action: PayloadAction<UpdateProfilePicInput>,
) {
  const { data, errors } = yield call(
    ApiService.updateCurrentUserProfilePic,
    action.payload,
  );

  if (errors) {
    yield put(updateProfilePicError(errors));
  } else {
    yield put(updateProfilePicSuccess(data));
  }
}

export function* updateProfileAsync(action: PayloadAction<UpdateProfileInput>) {
  const { data, errors } = yield call(ApiService.updateProfile, action.payload);

  if (errors) {
    yield put(updateProfileError(errors));
  } else {
    yield put(updateProfileSuccess(data));
  }
}

export function* changePasswordAsync(
  action: PayloadAction<ChangePasswordInput>,
) {
  const { data, errors } = yield call(
    ApiService.changePassword,
    action.payload,
  );

  if (errors) {
    yield put(changePasswordError(errors));
  } else {
    yield put(changePasswordSuccess(data));
  }
}

export function* showAddressAsync() {
  const { data, errors } = yield call(ApiService.getAddress);

  if (errors) {
    yield put(showAddressError(errors));
  } else {
    yield put(showAddressSuccess(data));
  }
}

export function* loadUserBetsAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.fetchUserBets, action.payload);

  if (!data) {
    yield put(loadUserBetsError(['404']));
  } else {
    yield put(loadUserBetsSuccess(data));
  }
}

export function* loadMyPoolBetsAsync(action: PayloadAction<string>) {
  const { data } = yield call(ApiService.myPoolBets, action.payload);

  if (!data) {
    yield put(loadMyPoolBetsError(['404']));
  } else {
    yield put(loadMyPoolBetsSuccess(data));
  }
}

export function* loadCardInfoAsync() {
  const { data, errors } = yield call(ApiService.loadCardInfo);

  if (errors) {
    yield put(loadCardInfoError(errors));
  } else {
    yield put(loadCardInfoSuccess(data));
  }
}

export function* setDefaultPaymentMethodAsync(
  action: PayloadAction<ChangeDefaultCardInput>,
) {
  const { data, errors } = yield call(
    ApiService.setDefaultPaymentMethod,
    action.payload,
  );

  if (errors) {
    yield put(setDefaultPaymentMethodError(errors));
  } else {
    yield put(setDefaultPaymentMethodSuccess(data));
  }
}

export function* deletePaymentMethodAsync(
  action: PayloadAction<ChangeDefaultCardInput>,
) {
  const { data, errors } = yield call(
    ApiService.deletePaymentMethod,
    action.payload,
  );

  if (errors) {
    yield put(deletePaymentMethodError(errors));
  } else {
    yield put(deletePaymentMethodSuccess(data));
  }
}

export function* openPaymentOrderAsync(
  action: PayloadAction<OpenPaymentOrderInput>,
) {
  const { data, errors } = yield call(
    ApiService.openPaymentOrder,
    action.payload,
  );

  if (errors) {
    yield put(openPaymentOrderError(errors));
  } else {
    yield put(openPaymentOrderSuccess(data));
  }
}

export function* generateWithdrawUrlAsync(
  action: PayloadAction<OpenPaymentOrderInput>,
) {
  const { data, errors } = yield call(
    ApiService.generateWithdrawUrl,
    action.payload,
  );

  if (errors) {
    yield put(generateWithdrawUrlError(errors));
  } else {
    yield put(generateWithdrawUrlSuccess(data));
  }
}

export function* getWithdrawalRequestsAsync() {
  const { data, errors } = yield call(ApiService.getWithdrawalRequests);

  if (errors) {
    yield put(getWithdrawalRequestsError(errors));
  } else {
    yield put(getWithdrawalRequestsSuccess(data));
  }
}

export function* addMoneyToWalletAsync(
  action: PayloadAction<AddMoneyToWalletInput>,
) {
  const { data, errors } = yield call(
    ApiService.addMoneyToWallet,
    action.payload,
  );

  if (errors) {
    yield put(addMoneyToWalletError(['404']));
  } else {
    yield put(addMoneyToWalletSuccess(data));
  }
}

export function* deductMoneyFromWalletAsync(
  action: PayloadAction<DeductMoneyFromWalletInput>,
) {
  const { data, errors } = yield call(
    ApiService.deductMoneyFromWallet,
    action.payload,
  );

  if (errors) {
    yield put(deductMoneyFromWalletError(['404']));
  } else {
    yield put(deductMoneyFromWalletSuccess(data));
  }
}

export function* sendTokenToVerifyMobileAsync(
  action: PayloadAction<VerifyMobileInput>,
) {
  const { data, errors } = yield call(
    ApiService.sendTokenToVerifyMobile,
    action.payload,
  );

  if (errors) {
    yield put(sendTokenToVerifyMobileError(errors));
  } else {
    yield put(sendTokenToVerifyMobileSuccess(data));
  }
}

export function* checkTokenToVerifyMobileAsync(
  action: PayloadAction<VerifyTokenInput>,
) {
  const { data, errors } = yield call(
    ApiService.checkTokenToVerifyMobile,
    action.payload,
  );

  if (errors) {
    yield put(checkTokenToVerifyMobileError(errors));
  } else {
    yield put(checkTokenToVerifyMobileSuccess(data));
  }
}

export function* disableTwoFAAsync() {
  const { data, errors } = yield call(ApiService.disableTwoFA);

  if (errors) {
    yield put(disableTwoFAError(errors));
  } else {
    yield put(disableTwoFASuccess(data));
  }
}

export function* watchProfileAsync() {
  yield takeEvery(updateProfilePic.toString(), updateProfilePicAsync);
  yield takeEvery(updateProfile.toString(), updateProfileAsync);
  yield takeEvery(changePassword.toString(), changePasswordAsync);
  yield takeEvery(loadUserBets.toString(), loadUserBetsAsync);
  yield takeEvery(loadMyPoolBets.toString(), loadMyPoolBetsAsync);
  yield takeEvery(showAddress.toString(), showAddressAsync);

  yield takeEvery(loadCardInfo.toString(), loadCardInfoAsync);
  yield takeEvery(
    setDefaultPaymentMethod.toString(),
    setDefaultPaymentMethodAsync,
  );
  yield takeEvery(deletePaymentMethod.toString(), deletePaymentMethodAsync);
  yield takeEvery(openPaymentOrder.toString(), openPaymentOrderAsync);
  yield takeEvery(generateWithdrawUrl.toString(), generateWithdrawUrlAsync);
  yield takeEvery(getWithdrawalRequests.toString(), getWithdrawalRequestsAsync);
  yield takeEvery(addMoneyToWallet.toString(), addMoneyToWalletAsync);
  yield takeEvery(deductMoneyFromWallet.toString(), deductMoneyFromWalletAsync);
  yield takeEvery(
    sendTokenToVerifyMobile.toString(),
    sendTokenToVerifyMobileAsync,
  );
  yield takeEvery(
    checkTokenToVerifyMobile.toString(),
    checkTokenToVerifyMobileAsync,
  );
  yield takeEvery(disableTwoFA.toString(), disableTwoFAAsync);
}
