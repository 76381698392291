import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LanguageTexts from '../../../common/language';
import { RootState } from '../../../common/types';
import { whoami } from '../../login/login.slice';
import {
  checkTokenToVerifyMobile,
  disableTwoFA,
  resetCheckTokenToVerifyMobileSuccess,
  resetDisableTwoFASuccess,
  resetSendTokenToVerifyMobileSuccess,
  sendTokenToVerifyMobile,
} from '../profile.slice';
import TwoFactorAuthForm from './TwoFactorAuthForm';

const TwoFactorAuthPage: React.FC = (): JSX.Element => {
  const { app: appTxt, profile: profileTxt } = LanguageTexts;
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const { user } = useSelector((state: RootState) => state.login);
  const {
    sendTokenToVerifyMobile: {
      loading: sendTokenToVerifyMobileLoading,
      success: sendTokenToVerifyMobileSuccess,
      errors: sendTokenToVerifyMobileErrors,
    },
    checkTokenToVerifyMobile: {
      loading: checkTokenToVerifyMobileLoading,
      success: checkTokenToVerifyMobileSuccess,
      errors: checkTokenToVerifyMobileErrors,
    },
    disableTwoFA: {
      loading: disableTwoFALoading,
      success: disableTwoFASuccess,
      errors: disableTwoFAErrors,
    },
  } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    if (sendTokenToVerifyMobileSuccess) {
      toast.success('OTP Sent Successfully.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      sendTokenToVerifyMobileErrors &&
      sendTokenToVerifyMobileErrors.length > 0
    ) {
      toast.error(sendTokenToVerifyMobileErrors[0], {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [sendTokenToVerifyMobileSuccess, sendTokenToVerifyMobileErrors, dispatch]);

  useEffect(() => {
    if (checkTokenToVerifyMobileSuccess) {
      dispatch(resetSendTokenToVerifyMobileSuccess());
      dispatch(resetCheckTokenToVerifyMobileSuccess());
      dispatch(whoami());
      setEditMode(false);
      toast.success('2FA Enabled Successfully.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      checkTokenToVerifyMobileErrors &&
      checkTokenToVerifyMobileErrors.length > 0
    ) {
      toast.error(checkTokenToVerifyMobileErrors[0], {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [
    checkTokenToVerifyMobileSuccess,
    checkTokenToVerifyMobileErrors,
    dispatch,
  ]);

  useEffect(() => {
    if (disableTwoFASuccess) {
      dispatch(resetDisableTwoFASuccess());
      dispatch(whoami());
      toast.success('2FA Disabled Successfully.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (disableTwoFAErrors && disableTwoFAErrors.length > 0) {
      toast.error(disableTwoFAErrors[0], {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [disableTwoFASuccess, disableTwoFAErrors, dispatch]);

  function onSendOtp(phone: string) {
    dispatch(sendTokenToVerifyMobile({ phone }));
  }

  function onCounterDone() {
    dispatch(resetSendTokenToVerifyMobileSuccess());
  }

  function onCancel() {
    setEditMode(false);
    dispatch(resetSendTokenToVerifyMobileSuccess());
  }

  function onVerifyOtp(token: string) {
    dispatch(
      checkTokenToVerifyMobile({ token: Number.parseInt(token, 10) || 0 }),
    );
  }

  function disableTwoFactorAuth() {
    confirmAlert({
      title: profileTxt.twoFactorDisableTxt,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => {
            dispatch(disableTwoFA());
          },
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  return (
    <>
      <h3 className="text-8 font-weight-400 mb-4">
        {profileTxt.twoFactorAuthHeading}
      </h3>
      {!editMode && user?.twoFA?.isEnabled ? (
        <div>
          <p>
            2FA is Enabled for <b>{user.twoFA.mobile}</b>
          </p>
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={() => setEditMode(true)}
          >
            Change Mobile
          </button>
          <button
            type="button"
            className="btn btn-primary ml-2"
            disabled={disableTwoFALoading}
            onClick={() => disableTwoFactorAuth()}
          >
            {profileTxt.twoFactorDisableTxt}
          </button>
        </div>
      ) : null}

      {!editMode && !user?.twoFA?.isEnabled ? (
        <div>
          <p>2FA is Disabled. Click below button to enable.</p>
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={() => setEditMode(true)}
          >
            Enable 2FA
          </button>
        </div>
      ) : null}

      {editMode ? (
        <TwoFactorAuthForm
          loading={
            sendTokenToVerifyMobileLoading || checkTokenToVerifyMobileLoading
          }
          otpSent={sendTokenToVerifyMobileSuccess}
          showCancel
          onSendOtp={onSendOtp}
          onCancel={onCancel}
          onVerifyOtp={onVerifyOtp}
          onCounterDone={onCounterDone}
        />
      ) : null}
    </>
  );
};

export default TwoFactorAuthPage;
