/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, Formik } from 'formik';
import React from 'react';

import LanguageTexts from '../../common/language';
import { ForgotPasswordInput } from '../../common/types';

type ForgotPasswordProps = {
  onSubmit: (data: ForgotPasswordInput) => void;
  errors: string[];
  loading: boolean;
};

const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
  onSubmit,
  loading,
}: ForgotPasswordProps): JSX.Element => {
  const { forgotPassword: forgotPasswordTxt } = LanguageTexts;

  const initialValues: ForgotPasswordInput = {
    email: '',
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.email) {
      errors.email = forgotPasswordTxt.emailErrorText;
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isValidating }) => (
          <Form className="my-login-validation">
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-4">
                  <label htmlFor="email" className="text-muted font-weight-500">
                    {forgotPasswordTxt.emailAddressLabel}
                  </label>
                  <Field name="email" type="text" className="form-control" />
                  {errors.email && touched.email && (
                    <div className="invalid-feedback-msg">{errors.email}</div>
                  )}
                </div>
                <div className="form-text text-muted font-weight-500 mb-4">
                  {forgotPasswordTxt.resetPasswordMsg}
                </div>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {forgotPasswordTxt.resetPasswordBtnText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
