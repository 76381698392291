import React from 'react';
import { Link } from 'react-router-dom';

import LanguageTexts from '../../common/language';

const EmailVerifySuccessMessagePage: React.FC = (): JSX.Element | null => {
  const { login: loginTxt } = LanguageTexts;
  return (
    <>
      <div
        className="main-container"
        style={{
          background: `linear-gradient(0deg, #4d96f6f0 50%, #1a4eae8a 100%), url(${process.env.REACT_APP_IMAGE_PATH}website/bg-login.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-md-center h-100">
              <div>
                <div className="card fat rounded">
                  <div className="card-body">
                    <div className="w-50 mx-auto text-center mb-4">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PATH}website/logo.png`}
                        alt=""
                        width="220px"
                        height="140px"
                        className="img-fluid"
                      />
                    </div>
                    <h3 className="text-13 font-weight-500 text-primary text-center mb-4">
                      {loginTxt.emailVerifySuccessHeading}
                    </h3>
                    <p className="text-8 font-weight-500 text-muted text-center">
                      {loginTxt.emailVerifySuccessMsg}
                    </p>
                    <div className="text-center">
                      <Link to="/login" className="btn btn-primary">
                        {loginTxt.loginText}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

EmailVerifySuccessMessagePage.defaultProps = {};

export default EmailVerifySuccessMessagePage;
