import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LanguageTexts from '../../../../common/language';
import { RootState } from '../../../../common/types';

const CurrentBalancePage: React.FC = (): JSX.Element => {
  const { app: appTxt } = LanguageTexts;
  const { profile: profileTxt } = LanguageTexts;
  const { user } = useSelector((state: RootState) => state.login);

  const windowScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="text-light my-3 wallet-icon">
        <i className="fas fa-wallet" />
      </div>
      <h3 className="wallet-amount">
        {appTxt.currencySymbol}
        {user
          ? Number(user.wallet.toFixed(2))
              .toLocaleString()
              .split(/\s/)
              .join(',')
          : 0}
      </h3>
      <p className="mb-2 text-muted current-balance-text">
        {profileTxt.currentBalanceText}
      </p>
      <hr className="mx-n3" />
      <div className="d-flex withdraw-deposit-btn">
        <Link
          to="/withdraw"
          className="btn-link mr-auto"
          onClick={windowScrollTop}
        >
          {profileTxt.withdrawLinkText}
        </Link>
        <Link
          to="/deposit"
          className="btn-link ml-auto"
          onClick={windowScrollTop}
        >
          {profileTxt.depositLinkText}
        </Link>
      </div>
    </>
  );
};

export default CurrentBalancePage;
