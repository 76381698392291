import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LanguageTexts from '../../common/language';
import { RegisterInput, RootState } from '../../common/types';
import { loadCountries, register } from './register.slice';
import RegisterForm from './RegisterForm';

const RegisterPage: React.FC = (): JSX.Element | null => {
  const { register: registerTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const match = useLocation();
  const { errors, loading } = useSelector((state: RootState) => state.register);
  const { countries } = useSelector((state: RootState) => state.register);
  const [loadRequested, setLoadRequested] = useState(false);
  // eslint-disable-next-line prefer-destructuring
  const state: any = match.state;

  useEffect(() => {
    if (!loadRequested) {
      dispatch(loadCountries());
      setLoadRequested(true);
    }
  }, [loadRequested, dispatch]);

  function onSubmit(input: RegisterInput) {
    dispatch(register(input));
  }

  return (
    <>
      <div 
        className="main-container register-page"
        style={{
          background: `linear-gradient(0deg, #4d96f6f0 50%, #1a4eae8a 100%), url(${process.env.REACT_APP_IMAGE_PATH}website/bg-login.jpg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      >
        <section className="h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100">
              <div className="card-wrapper">
                <div className="card fat">
                  <div className="card-body">
                    <h3 className="text-10 font-weight-500 mb-5">
                      {registerTxt.registerText}
                    </h3>
                    <RegisterForm
                      countries={countries || []}
                      onSubmit={onSubmit}
                      errors={errors}
                      socialLoginData={state}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

RegisterPage.defaultProps = {};

export default RegisterPage;
