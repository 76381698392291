/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ContactUsInput } from '../../common/types';
import ApiService from '../../services/api';
import {
  addContactUs,
  addContactUsError,
  addContactUsSuccess,
  loadPools,
  loadPoolsError,
  loadPoolsSuccess,
  loadSportTypes,
  loadSportTypesError,
  loadSportTypesSuccess,
} from './home.slice';

export function* loadPoolAsync() {
  const { data } = yield call(ApiService.getPools);

  if (!data) {
    yield put(loadPoolsError(['404']));
  } else {
    yield put(loadPoolsSuccess(data));
  }
}

export function* loadSportTypeAsync() {
  const { data } = yield call(ApiService.getSportTypes);

  if (!data) {
    yield put(loadSportTypesError(['404']));
  } else {
    yield put(loadSportTypesSuccess(data));
  }
}

export function* addContactUsAsync(action: PayloadAction<ContactUsInput>) {
  const { data, errors } = yield call(ApiService.addContactUs, action.payload);

  if (errors) {
    yield put(addContactUsError(errors));
  } else {
    yield put(addContactUsSuccess(data));
  }
}

export function* watchPoolAsync() {
  yield takeLatest(loadPools.toString(), loadPoolAsync);
  yield takeLatest(loadSportTypes.toString(), loadSportTypeAsync);
  yield takeLatest(addContactUs.toString(), addContactUsAsync);
}
