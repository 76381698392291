import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LanguageTexts from '../../../common/language';
import { RootState, UpdateProfilePicInput } from '../../../common/types';
import {
  resetUpdateProfilePicSuccess,
  updateProfilePic,
} from '../profile.slice';
import ProfilePhotoForm from './ProfilePhotoForm';

const ProfilePhotoPage: React.FC = (): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    showProfilePic,
    uploadProfilePic: { loading, success },
  } = useSelector((state: RootState) => state.profile);

  function onSubmit(data: UpdateProfilePicInput) {
    dispatch(updateProfilePic(data));
  }

  useEffect(() => {
    if (success) {
      dispatch(resetUpdateProfilePicSuccess());
      toast.success(profileTxt.uploadPhotoSuccessMsg, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [success, dispatch, history]);

  const updateProfilePicInit = showProfilePic ? cloneDeep(showProfilePic) : {};

  return (
    <>
      <ProfilePhotoForm
        onSubmit={onSubmit}
        loading={loading}
        initialValues={updateProfilePicInit}
      />
    </>
  );
};

export default ProfilePhotoPage;
