/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import LanguageTexts from '../common/language';

type ImageUploadProps = {
  id: string;
  label: string;
  onFileSelect: (image: File | null) => void;
  onRemoveUrl?: undefined | (() => void);
  url?: string | null;
  required?: boolean;
  maxFileSize?: number | null;
  disabled?: boolean;
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  id,
  label,
  onFileSelect,
  onRemoveUrl,
  url,
  required,
  maxFileSize,
  disabled,
}: ImageUploadProps): JSX.Element => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [previewFile, setPreviewFile] = useState<File | null>(null);
  const [maxSizeErr, setMaxSizeErr] = useState<string | null>(null);

  function removePreviewImage(e: React.MouseEvent) {
    e.preventDefault();
    if (disabled) return;
    setPreviewImage(null);
    onFileSelect(null);
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { files },
    } = e;

    if (files && files.length > 0) {
      setMaxSizeErr(null);

      const file = files[0];
      const sizeInKB = Math.round(file.size / 1024);

      if (maxFileSize && sizeInKB > maxFileSize) {
        setMaxSizeErr(
          LanguageTexts.app.maxSizeErr.replace(
            '{size}',
            `${maxFileSize / 1024}MB`,
          ),
        );
        return;
      }

      const reader = new FileReader();

      reader.onload = function onLoad() {
        setPreviewImage(reader.result ? reader.result.toString() : null);
        onFileSelect(file);
      };

      reader.readAsDataURL(file);

      setPreviewFile(file);
      onFileSelect(file);
      e.target.value = '';
    }
  }

  return (
    <>
      <div className="profile-thumb mt-3 mb-4">
        {previewImage || url ? (
          <div className="uploaded-image">
            <img
              className="rounded-circle"
              src={previewImage || url || ''}
              alt=""
            />
            {previewImage ? (
              <Link
                className="remove-pic-btn"
                onClick={removePreviewImage}
                to="/"
              >
                <i className="fas fa-times" />
              </Link>
            ) : null}
          </div>
        ) : (
          <img
            className="rounded-circle"
              src={url || `${process.env.REACT_APP_IMAGE_PATH}website/user.png`}
            alt=""
          />
        )}
        <div className="profile-thumb-edit custom-file bg-primary text-white">
          <i className="fas fa-camera position-absolute" />
          <div className="form-group">
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id={id}
                  aria-describedby="inputGroupFileAddon04"
                  onChange={onFileChange}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ImageUpload.defaultProps = {
  url: null,
  maxFileSize: null,
  required: false,
  disabled: false,
  onRemoveUrl: undefined,
};

export default ImageUpload;
