import React from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import { AppRoutePath, PublicRoutePath } from '../common/constants';
import { RootState } from '../common/types';
import LayoutUser from './layout/UserLayout';

type PublicRouteProps = {
  children: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const PublicRoute: React.FC<PublicRouteProps> = ({
  children,
  ...rest
}: PublicRouteProps): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.login);
  const history = useHistory();

  if (
    user &&
    (history.location.pathname === PublicRoutePath.Login ||
      history.location.pathname === PublicRoutePath.Register ||
      history.location.pathname === PublicRoutePath.ForgotPass)
  ) {
    history.push(AppRoutePath.Home);
    return <></>;
  }

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => <LayoutUser>{children}</LayoutUser>}
    />
  );
};

export default PublicRoute;
