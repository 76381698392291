import React from 'react';

import Footer from './Footer';
import TopHeader from './TopHeader';

type LayoutUserProps = {
  children: JSX.Element;
};

const LayoutUser: React.FC<LayoutUserProps> = ({
  children,
}: LayoutUserProps): JSX.Element => {
  return (
    <>
      <TopHeader />
      <div className="content">{children}</div>
      <Footer />
    </>
  );
};

export default LayoutUser;
