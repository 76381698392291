/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import React from 'react';

import LanguageTexts from '../../../common/language';
import BetStatusBadge from '../../../components/BetStatusBadge';
import UserBetDisplay from '../../../components/UserBetDisplay';

type PoolUserBetsProps = {
  records: any[];
  title: string;
};

const PoolUserBets: React.FC<PoolUserBetsProps> = ({
  records,
  title,
}: PoolUserBetsProps): JSX.Element => {
  const { app: appTxt, account: accountTxt } = LanguageTexts;
  return (
    <>
      <div className="card-box bg-light shadow-sm rounded pt-4 mb-4">
        <h3 className="text-8 font-weight-400 px-4 mb-4">{title}</h3>

        <div className="pool-game-bet-parent">
          <div className="pool-game-bet">
            <div className="current-game-title py-2 px-4 pr-5">
              <div className="row">
                <div className="col-1" />
                <div className="col-3">{accountTxt.tableHeadingPoolText}</div>
                <div className="col-2">{accountTxt.tableHeadingPointsText}</div>
                <div className="col-2">
                  {accountTxt.tableHeadingWinnerRankText}
                </div>
                <div className="col-4">{accountTxt.tableHeadingAmountText}</div>
              </div>
            </div>

            <div className="current-game-list">
              {records.map((pool, index) => {
                return (
                  <div id={`accordion-${index}`} key={`accordion-${index}`}>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="collapsed card-link row align-items-center flex-row"
                          data-toggle="collapse"
                          href={`#collapse${index}`}
                        >
                          <div className="col-1 text-center">
                            <span
                              id="collapsible-icon"
                              className="fas fa-chevron-right collapsible-icon"
                            />
                          </div>
                          <div className="col-3">
                            <span className="d-block text-5 font-weight-300">
                              {moment(pool.createdAt).format('DD')}{' '}
                              {moment(pool.createdAt).format('MMM')}
                            </span>
                            <span className="d-block text-3 font-weight-400 text-muted text-uppercase">
                              {pool.pool.name}
                            </span>
                          </div>
                          <div className="col-2">
                            <span className="d-block text-5 font-weight-300">
                              {Number(pool.coins.toFixed(0))
                                .toLocaleString()
                                .split(/\s/)
                                .join(',')}
                            </span>
                          </div>
                          <div className="col-2">
                            <span className="d-block text-5 font-weight-300 primary-color">
                              {pool.winnerRank ? `#${pool.winnerRank}` : '-'}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block text-5 font-weight-300">
                              {pool.winningAmount
                                ? `$${Number(pool.winningAmount.toFixed(2))
                                    .toLocaleString()
                                    .split(/\s/)
                                    .join(',')}`
                                : '-'}
                            </span>
                          </div>
                        </a>
                      </div>
                      <div
                        id={`collapse${index}`}
                        className="collapse"
                        data-parent={`#accordion-${index}`}
                      >
                        <div className="card-body">
                          <div className="current-game-title py-2 px-4 pr-5">
                            <div className="row">
                              <div className="col-3">
                                {accountTxt.tableHeadingGamesText}
                              </div>
                              <div className="col-2">
                                {accountTxt.tableHeadingBetTypesText}
                              </div>
                              <div className="col-2">
                                {accountTxt.tableHeadingTotalWagerText}
                              </div>
                              <div className="col-2">
                                {accountTxt.tableHeadingOddsText}
                              </div>
                              <div className="col-2">
                                {accountTxt.tableHeadingPayoutText}
                              </div>
                              <div className="col-1" />
                            </div>
                          </div>
                          {pool.userBets.map((userBet: any, index2: number) => {
                            return userBet.payload.length > 1 ? (
                              <div
                                id={`accordion-l2-${index2}`}
                                key={`accordion-l2-${index2}`}
                              >
                                <div className="card parlayCard">
                                  <div className="card-header">
                                    <a
                                      className="collapsed card-link row align-items-center flex-row"
                                      data-toggle="collapse"
                                      href={`#collapse-l2${index2}`}
                                    >
                                      <div className="col-3" />
                                      <div className="col-2 text-white">
                                        {accountTxt.betTypeParlayText}
                                      </div>
                                      <div className="col-2 text-white">
                                        {Number(userBet.points.toFixed(0))
                                          .toLocaleString()
                                          .split(/\s/)
                                          .join(',')}
                                      </div>
                                      <div className="col-2 text-white">
                                        {Number(userBet.winPoints.toFixed(0))
                                          .toLocaleString()
                                          .split(/\s/)
                                          .join(',')}
                                      </div>
                                      <div className="col-2 text-white">
                                        {userBet.isWin !== null ? (
                                          <div>
                                            <span className="text-5 text-nowrap">
                                              {userBet.isWin ||
                                              userBet.isRefundable
                                                ? `+${Number(
                                                    userBet.totalPoints.toFixed(
                                                      0,
                                                    ),
                                                  )
                                                    .toLocaleString()
                                                    .split(/\s/)
                                                    .join(',')}`
                                                : `-${Number(
                                                    userBet.totalPoints.toFixed(
                                                      0,
                                                    ),
                                                  )
                                                    .toLocaleString()
                                                    .split(/\s/)
                                                    .join(',')}`}
                                            </span>
                                            <span className="text-5 text-uppercase" />
                                          </div>
                                        ) : (
                                          Number(userBet.totalPoints.toFixed(0))
                                            .toLocaleString()
                                            .split(/\s/)
                                            .join(',')
                                        )}
                                      </div>
                                      <div className="col-1">
                                        <BetStatusBadge
                                          isRefundable={userBet.isRefundable}
                                          isWin={userBet.isWin}
                                        />
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    id={`collapse-l2${index2}`}
                                    className="collapse"
                                    data-parent={`#accordion-l2-${index2}`}
                                  />
                                  <div className="card-body">
                                    {userBet.payload.map(
                                      (betPayload: any, index3: any) => (
                                        <div
                                          className="current-game-item py-3"
                                          key={`user-bet-${userBet._id}-${index3}`}
                                        >
                                          <div className="row">
                                            <div className="col-3">
                                              <span className="d-block text-5 font-weight-300">
                                                {moment(
                                                  betPayload.game.date,
                                                ).format('DD')}
                                                &nbsp;
                                                {moment(
                                                  betPayload.game.date,
                                                ).format('MMM')}
                                              </span>
                                              <span className="d-block text-3 font-weight-400 text-muted">
                                                {betPayload.teamType === 'away'
                                                  ? betPayload.game.awayTeam
                                                      .name
                                                  : betPayload.game.homeTeam
                                                      .name}
                                                {betPayload.game.isCancelled ? (
                                                  <span
                                                    style={{ color: 'red' }}
                                                    title="Cancelled"
                                                  >
                                                    &nbsp;X
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                              </span>
                                            </div>
                                            <div className="col-2">
                                              <span className="d-block text-5 font-weight-300">
                                                {betPayload.betType.name}
                                              </span>
                                              <span className="d-block text-3 font-weight-400 text-muted">
                                                <UserBetDisplay
                                                  type={betPayload.betType.name}
                                                  teamType={betPayload.teamType}
                                                  bet={betPayload.bet}
                                                />
                                              </span>
                                            </div>
                                            <div className="col-6" />
                                            <div className="col-1">
                                              <BetStatusBadge
                                                isRefundable={
                                                  betPayload.isRefundable
                                                }
                                                isWin={betPayload.isWin}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="card-body">
                                {userBet.payload.map(
                                  (betPayload: any, index4: any) => (
                                    <div
                                      className="current-game-item py-3"
                                      key={`user-bet-${userBet._id}-${index4}`}
                                    >
                                      <div className="row">
                                        <div className="col-3">
                                          <span className="d-block text-5 font-weight-300">
                                            {moment(
                                              betPayload.game.date,
                                            ).format('DD')}
                                            &nbsp;
                                            {moment(
                                              betPayload.game.date,
                                            ).format('MMM')}
                                          </span>
                                          <span className="d-block text-3 font-weight-400 text-muted">
                                            {betPayload.teamType === 'away'
                                              ? betPayload.game.awayTeam.name
                                              : betPayload.game.homeTeam.name}
                                            {betPayload.game.isCancelled ? (
                                              <span
                                                style={{ color: 'red' }}
                                                title="Cancelled"
                                              >
                                                &nbsp;X
                                              </span>
                                            ) : (
                                              ''
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-2">
                                          <span className="d-block text-5 font-weight-300">
                                            {betPayload.betType.name}
                                          </span>
                                          <span className="d-block text-3 font-weight-400 text-muted">
                                            <UserBetDisplay
                                              type={betPayload.betType.name}
                                              teamType={betPayload.teamType}
                                              bet={betPayload.bet}
                                            />
                                          </span>
                                        </div>
                                        <div className="col-2">
                                          {Number(userBet.points.toFixed(0))
                                            .toLocaleString()
                                            .split(/\s/)
                                            .join(',')}
                                        </div>
                                        <div className="col-2">
                                          {Number(userBet.winPoints.toFixed(0))
                                            .toLocaleString()
                                            .split(/\s/)
                                            .join(',')}
                                        </div>
                                        <div className="col-2">
                                          {userBet.isWin !== null ? (
                                            <div>
                                              <span className="text-5 text-nowrap">
                                                {userBet.isWin ||
                                                userBet.isRefundable
                                                  ? `+${Number(
                                                      userBet.totalPoints.toFixed(
                                                        0,
                                                      ),
                                                    )
                                                      .toLocaleString()
                                                      .split(/\s/)
                                                      .join(',')}`
                                                  : `-${Number(
                                                      userBet.totalPoints.toFixed(
                                                        0,
                                                      ),
                                                    )
                                                      .toLocaleString()
                                                      .split(/\s/)
                                                      .join(',')}`}
                                              </span>
                                              <span className="text-5 text-uppercase" />
                                            </div>
                                          ) : (
                                            Number(
                                              userBet.totalPoints.toFixed(0),
                                            )
                                              .toLocaleString()
                                              .split(/\s/)
                                              .join(',')
                                          )}
                                        </div>
                                        <div className="col-1">
                                          <BetStatusBadge
                                            isRefundable={
                                              betPayload.isRefundable
                                            }
                                            isWin={betPayload.isWin}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoolUserBets;
