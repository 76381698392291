import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import LanguageTexts from '../../../common/language';
import { RootState, UpdateProfilePicInput } from '../../../common/types';
import ImageUpload from '../../../components/ImageUpload';

type ProfilePhotoFormProps = {
  onSubmit: (data: UpdateProfilePicInput) => void;
  loading: boolean;
  initialValues: UpdateProfilePicInput;
};

const ProfilePhotoForm: React.FC<ProfilePhotoFormProps> = ({
  onSubmit,
  loading,
  initialValues,
}: ProfilePhotoFormProps): JSX.Element => {
  const { profile: profileTxt } = LanguageTexts;
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { user } = useSelector((state: RootState) => state.login);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const input = { ...values };

          if (selectedImage) {
            input.profilePic = selectedImage;
          }

          onSubmit(input);
          setSubmitting(false);
        }}
      >
        {() => {
          return (
            <Form>
              <ImageUpload
                id="profile-photo"
                label=""
                onFileSelect={(file) => {
                  setSelectedImage(file);
                }}
                url={user?.profilePicUrl}
                disabled={loading}
              />
              <p className="font-weight-500">
                {profileTxt.helloText} {user?.firstName} {user?.lastName}
              </p>
              <button
                type="submit"
                className="btn btn-primary btn-sm mb-2"
                disabled={loading}
              >
                {profileTxt.uploadBtnText}
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ProfilePhotoForm;
