import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import LanguageTexts from '../../../../common/language';
import { OpenPaymentOrderInput, RootState } from '../../../../common/types';

type IWithdrawalProps = {
  onSubmit: (data: OpenPaymentOrderInput) => void;
  errors: string[];
  loading: boolean;
};

const WithdrawMoneyForm: React.FC<IWithdrawalProps> = ({
  onSubmit,
  errors: errorsOut,
  loading,
}: IWithdrawalProps): JSX.Element => {
  const { account: accountTxt, app: appTxt } = LanguageTexts;
  const { user } = useSelector((state: RootState) => state.login);
  const initialValues: OpenPaymentOrderInput = {
    amount: 0,
  };

  const validate = (values: any) => {
    const errorsIn: any = {};

    if (!values.amount) {
      errorsIn.amount = accountTxt.amountErrorText;
    } else if (user && values.amount > user.wallet) {
      errorsIn.amount = accountTxt.withdrawalAmountLimitErrorText;
    }

    return errorsIn;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ errors, touched, isValidating }) => (
          <Form className="my-login-validation">
            <div className="form-group mb-4">
              <label
                htmlFor="youWithdraw"
                className="text-muted font-weight-500"
              >
                {accountTxt.amountLabel}
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text text-6">
                    {appTxt.currencySymbol}
                  </span>
                </div>
                <Field
                  type="number"
                  name="amount"
                  className="form-control"
                  data-bv-field="youWithdraw"
                  id="youWithdraw"
                  placeholder=""
                />
              </div>
              {errors.amount && touched.amount && (
                <div className="invalid-feedback-msg">{errors.amount}</div>
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              {loading ? appTxt.loading : accountTxt.requestWithdrawBtnText}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WithdrawMoneyForm;
