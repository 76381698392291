import { Field, Form, Formik } from 'formik';
import React from 'react';
import Countdown from 'react-countdown';

import LanguageTexts from '../../../common/language';

type TwoFactorAuthFormProps = {
  loading: boolean;
  otpSent: boolean | null;
  showCancel: boolean;
  onCounterDone: () => void;
  onCancel: () => void;
  onSendOtp: (phone: string) => void;
  onVerifyOtp: (token: string) => void;
};

const TwoFactorAuthForm: React.FC<TwoFactorAuthFormProps> = ({
  loading,
  otpSent,
  showCancel,
  onCounterDone,
  onCancel,
  onSendOtp,
  onVerifyOtp,
}: TwoFactorAuthFormProps): JSX.Element => {
  const { profile: profileTxt, register: registerTxt } = LanguageTexts;

  const initialValues = {
    phone: '',
    token: '',
  };

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return null;
    }

    // Render a countdown
    return <p>Did not receive OTP yet? Try again after {seconds} seconds.</p>;
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.phone) {
      errors.phone = 'Mobile number is required.';
    } else if (!/^\+[1-9]{1}[0-9]{3,14}$/.test(values.phone)) {
      errors.phone =
        'Given mobile number is invalid. Please enter your number with country code (Ex. +19874563210).';
    }

    if (!values.token) {
      errors.token = 'OTP is required.';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        onVerifyOtp(values.token);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched }) => (
        <Form className="my-login-validation">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <label htmlFor="phone" className="text-muted font-weight-500">
                {registerTxt.mobileLabel}
              </label>
              <div className="input-group">
                <Field
                  name="phone"
                  type="text"
                  className="form-control"
                  placeholder="+19874563210"
                />
                {!otpSent ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    disabled={
                      !values.phone ||
                      !/^\+[1-9]{1}[0-9]{3,14}$/.test(values.phone) ||
                      loading
                    }
                    onClick={() => {
                      onSendOtp(values.phone);
                    }}
                  >
                    {profileTxt.sendOtpBtn}
                  </button>
                ) : null}
              </div>
              {otpSent ? (
                <div>
                  <Countdown
                    date={Date.now() + 59 * 1000}
                    onComplete={onCounterDone}
                    renderer={renderer}
                  />
                </div>
              ) : null}
              {errors.phone && touched.phone && (
                <div className="invalid-feedback-msg">{errors.phone}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <label htmlFor="mobile" className="text-muted font-weight-500">
                {profileTxt.otpLabel}
              </label>
              <Field
                name="token"
                type="text"
                className="form-control"
                placeholder="654321"
              />
              {errors.token && touched.token && (
                <div className="invalid-feedback-msg">{errors.token}</div>
              )}
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Verify OTP
          </button>
          {showCancel ? (
            <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={onCancel}
            >
              Cancel
            </button>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default TwoFactorAuthForm;
