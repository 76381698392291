/* eslint-disable no-nested-ternary */
import 'react-confirm-alert/src/react-confirm-alert.css';

import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { loadSafeCharge } from '../../../../common/helper';
import LanguageTexts from '../../../../common/language';
import {
  AddCardInput,
  OpenOrderModel,
  RootState,
} from '../../../../common/types';
import {
  deletePaymentMethod,
  loadCardInfo,
  openPaymentOrder,
  resetDeletePaymentMethod,
  resetOpenPaymentOrder,
  resetSetDefaultPaymentMethod,
  setDefaultPaymentMethod,
} from '../../profile.slice';
import SidePanelPage from '../../side-panel/SidePanelPage';
import CreditCardDetails from './CreditCardDetails';
import CreditCardForm from './CreditCardForm';

const CreditCardPage: React.FC = (): JSX.Element => {
  const { account: accountTxt } = LanguageTexts;
  const { app: appTxt } = LanguageTexts;
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [ccData, setCcData] = useState<any>();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [safeCharge, setSafeCharge] = useState<any>();

  const {
    userCardInfo,
    setDefaultPaymentMethod: {
      errors: setDefaultPaymentMethodErrors,
      success: setDefaultPaymentMethodSuccess,
    },
    deletePaymentMethod: {
      errors: deletePaymentMethodErrors,
      success: deletePaymentMethodSuccess,
    },
  } = useSelector((state: RootState) => state.profile);
  const {
    openOrder,
    openPaymentOrder: { success: openPaymentOrderSuccess },
  } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    loadSafeCharge().then((safe) => {
      setSafeCharge(safe);
    });
  }, []);

  useEffect(() => {
    const createPayment = (data: OpenOrderModel) => {
      try {
        if (ccData && safeCharge) {
          safeCharge.createPayment(
            {
              sessionToken: data.sessionToken,
              merchantId: process.env.REACT_APP_MERCHANT_ID,
              merchantSiteId: process.env.REACT_APP_MERCHANT_SITE_ID,
              clientUniqueId: data.clientUniqueId,
              paymentOption: {
                card: {
                  cardNumber: ccData.ccCardNumber,
                  cardHolderName: ccData.ccNameOnCard,
                  expirationMonth: ccData.ccExpMonth,
                  expirationYear: ccData.ccExpYear,
                },
              },
            },
            (resp: any) => {
              // eslint-disable-next-line no-console
              console.log('pay res>', resp);
              dispatch(loadCardInfo());
              setCcData(undefined);
              setPaymentLoading(false);
              setEditMode(false);
              toast.success(accountTxt.addNewCardSuccess, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            },
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    if (openPaymentOrderSuccess) {
      if (openOrder) {
        dispatch(resetOpenPaymentOrder());
        createPayment(openOrder);
      }
    }
  }, [
    openPaymentOrderSuccess,
    openOrder,
    dispatch,
    safeCharge,
    ccData,
    accountTxt,
  ]);

  function onSubmit(data: AddCardInput) {
    setCcData(data);
    setPaymentLoading(true);
    dispatch(openPaymentOrder({ amount: 0 }));
  }

  useEffect(() => {
    dispatch(loadCardInfo());
  }, [dispatch]);

  useEffect(() => {
    if (setDefaultPaymentMethodSuccess) {
      dispatch(resetSetDefaultPaymentMethod());
      dispatch(loadCardInfo());
    } else if (
      setDefaultPaymentMethodErrors &&
      setDefaultPaymentMethodErrors.length > 0
    ) {
      dispatch(resetSetDefaultPaymentMethod());
    }
  }, [setDefaultPaymentMethodSuccess, setDefaultPaymentMethodErrors, dispatch]);

  useEffect(() => {
    if (deletePaymentMethodSuccess) {
      dispatch(resetDeletePaymentMethod());
      dispatch(loadCardInfo());
      toast.success(accountTxt.deleteCardSuccess, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (
      deletePaymentMethodErrors &&
      deletePaymentMethodErrors.length > 0
    ) {
      toast.error(deletePaymentMethodErrors[0]);
      dispatch(resetDeletePaymentMethod());
    }
  }, [
    deletePaymentMethodSuccess,
    deletePaymentMethodErrors,
    accountTxt,
    dispatch,
  ]);

  function handleSetDefaultPaymentMethod(cardId: string) {
    dispatch(setDefaultPaymentMethod({ cardId }));
  }

  function handleDeletePaymentMethod(cardId: string) {
    confirmAlert({
      title: accountTxt.deleteCardTitle,
      message: appTxt.confirmText,
      buttons: [
        {
          label: appTxt.yes,
          onClick: () => dispatch(deletePaymentMethod({ cardId })),
        },
        {
          label: appTxt.no,
          onClick: () => null,
        },
      ],
    });
  }

  return (
    <>
      <div id="main-wrapper" className="bg-primary gradient-color userpage">
        <div id="content" className="py-4 main-user-section">
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape01-bg.png`}
            alt=""
            className="shape-1"
          />
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}website/shape02-bg.png`}
            alt=""
            className="shape-2"
          />
          <div className="container">
            <div className="row">
              <aside className="col-lg-3">
                <SidePanelPage />
              </aside>
              <div className="col-lg-9">
                <div className="card-box bg-light shadow-sm rounded p-4 mb-4">
                  <h3 className="text-8 font-weight-400 mb-4">
                    {!editMode ? (
                      <span>{accountTxt.cardsHeadingText}</span>
                    ) : (
                      <span>{accountTxt.addCardText}</span>
                    )}
                    {!editMode ? (
                      <button
                        type="button"
                        onClick={() => setEditMode(true)}
                        className="float-right text-5 text-uppercase text-muted btn-link border-0 bg-transparent"
                      >
                        <i className="fas fa-plus-circle mr-2" />
                        {accountTxt.addCardText}
                      </button>
                    ) : null}
                  </h3>
                  {!editMode ? (
                    userCardInfo && userCardInfo.paymentMethods.length > 0 ? (
                      <div className="row">
                        {userCardInfo.paymentMethods.map((cardData) => {
                          return (
                            <CreditCardDetails
                              data={cardData}
                              handleSetDefaultPaymentMethod={
                                handleSetDefaultPaymentMethod
                              }
                              handleDeletePaymentMethod={
                                handleDeletePaymentMethod
                              }
                            />
                          );
                        })}
                      </div>
                    ) : userCardInfo !== null ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <h6 className="text-secondary text-6">
                          {accountTxt.noCard}
                        </h6>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center">
                        <h6 className="text-secondary text-6">
                          {appTxt.loading}
                        </h6>
                      </div>
                    )
                  ) : (
                    <CreditCardForm
                      onSubmit={onSubmit}
                      errors={[]}
                      loading={paymentLoading}
                      onCancel={() => setEditMode(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCardPage;
